import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import React, { useRef, Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { useScroll, useWindowSize } from 'react-use';
import { withFormik, setNestedObjectValues } from 'formik';
import { pure, compose, lifecycle, withProps, withHandlers } from 'react-recompose';
// components
import { Tabs2, withTabs2, FormFooter2, LocalLoader } from '../../../../components';
// features
import { makeSelectAuthorities } from '../../../permission/selectors';
import { setExpandedContainerOptions } from '../../../expanded-container/actions';
// forms
import { PureStyledForm } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box, Flex, Text, SectionsDivider, scrollableContainerCss3px } from '../../../../ui';
// hocs
import {
  withFixedPopover,
  withAsyncRefTypes,
  withAsyncInitialDataOnDidMount,
  withConnectModalAndLoaderActions,
} from '../../../../hocs';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature template/route
import { getClos } from './create-routes-form';
import ScheduleActiveTab from './schedule-active-tab';
import {
  updateRouteTemplateRequest,
  createRouteFromTemplateRequest,
} from '../../report/actions';
import {
  scheduleTabsOptions,
  getValidationSchema,
  getDefaultTemplateFields,
} from '../settings/schedule-route-settings';
import {
  getNewRow,
} from '../settings/create-routes-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const darkMainDarkColor = G.getTheme('colors.dark.mainDark');

const commonSubmit = (props: Object) => {
  const {
    values,
    editTemplate,
    templateGuid,
    referenceTypes,
    asyncInitialData,
    temporaryTemplate,
    shouldGoToRouteBuilder,
    createRouteFromTemplateRequest,
  } = props;

  const clos = getClos(asyncInitialData);

  const cloGuids = R.map(G.getGuidFromObject, clos);

  const indexedRefTypes = G.indexByGuid(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes));

  const createDtos = R.compose(
    R.map(
      (routeData: Object) => {
        const { eventData, firstPickupStartDate } = routeData;

        const cloData = R.compose(
          R.values,
          R.mapObjIndexed(({ references, containers }: Array, cloGuid: string) => ({
            cloGuid,
            containers,
            references: R.map(
              (ref: Object) => R.assoc(
                GC.FIELD_NAME,
                R.path([R.prop(GC.FIELD_REFERENCE_TYPE_GUID, ref), GC.FIELD_NAME], indexedRefTypes),
                ref,
              ),
              references,
            ),
          })),
          R.pick(cloGuids),
        )(routeData);

        return {
          cloData,
          eventData,
          firstPickupStartDate,
        };
      },
    ),
    R.reduce(R.concat, []),
    R.values,
  )(R.pick(GC.shortDays, values));

  if (G.isTrue(editTemplate)) {
    return createRouteFromTemplateRequest({
      templateGuid,
      editTemplate,
      singleRoute: true,
      shouldGoToRouteBuilder,
      createDto: R.head(createDtos),
    });
  }

  if (R.equals(R.length(createDtos), 1)) {
    return createRouteFromTemplateRequest({
      templateGuid,
      singleRoute: true,
      temporaryTemplate,
      createDto: R.head(createDtos),
      shouldGoToRouteBuilder: false,
    });
  }

  createRouteFromTemplateRequest({
    createDtos,
    templateGuid,
    temporaryTemplate,
    singleRoute: false,
    shouldGoToRouteBuilder: false,
  });
};

const updateTemplate = (props: Object) => {
  const { values, templateGuid, updateRouteTemplateRequest } = props;

  const routesDetailsMap = R.compose(
    G.renameKeys(GC.shortDaysToDayKeysMap),
    R.mapObjIndexed((routes: Array) => R.map(
        (routeData: Object) => {
          const { uniq, eventData } = routeData;

          return {
            id: uniq,
            eventData,
          };
        },
        routes,
      ),
    ),
  )(R.pick(GC.shortDays, values));

  updateRouteTemplateRequest({
    routesDetailsMap,
    guid: templateGuid,
  });
};

const getRoutesLength = (values: Object) => R.compose(
  R.length,
  R.reduce(R.concat, []),
  R.values,
)(R.pick(GC.shortDays, values));

const deleteTemporaryTemplate = (guid: string) => {
  try {
    sendRequest(
      'delete',
      endpointsMap.routeTemplate,
      { data: R.of(Array, guid) },
    );
  } catch (error) {
    G.handleException('error', 'deleteTemporaryTemplate exception');
  }
};

const ScheduleRoute = (props: Object) => {
  const {
    values,
    activeTab,
    closeModal,
    templateGuid,
    templateName,
    handleSubmit,
    leftSpaceWidth,
    asyncInitialData,
    temporaryTemplate,
    handleSetActiveTab,
    handleUpdateTemplate,
    hideEditTemplateRoute,
    handleSaveWithoutClose,
  } = props;

  if (R.pathOr(true, ['loading'], asyncInitialData)) return <LocalLoader localLoaderOpen={true} />;

  const scrollRef = useRef(null);

  const { x } = useScroll(scrollRef);

  const { width } = useWindowSize();

  const widthToUse = R.add(R.subtract(width, R.add(3, R.or(leftSpaceWidth, 0))), x);

  const numberObStops = R.length(R.pathOr(0, ['data', 'events'], asyncInitialData));

  const routesLength = getRoutesLength(values);

  let actionButtons = [
    {
      action: handleUpdateTemplate,
      displayText: G.getWindowLocale('titles:update-template', 'Update Template'),
      buttonStyles: {
        mr: 16,
        ml: 'auto',
        width: 160,
        bgColor: 'none',
        background: 'none',
        border: '1px solid',
        borderRadius: '5px',
        textColor: G.getTheme('colors.dark.blue'),
        borderColor: G.getTheme('colors.dark.blue'),
      },
    },
    {
      action: handleSaveWithoutClose,
      displayText: G.getWindowLocale('actions:create-route', 'Create Route'),
      buttonStyles: {
        ml: 'auto',
        bgColor: 'none',
        background: 'none',
        border: '1px solid',
        borderRadius: '5px',
        mr: hideEditTemplateRoute ? 0 : 16,
        textColor: G.getTheme('colors.dark.blue'),
        borderColor: G.getTheme('colors.dark.blue'),
      },
    },
  ];

  if (temporaryTemplate) actionButtons = R.tail(actionButtons);

  const cancelAction = () => {
    closeModal();

    if (G.isTrue(temporaryTemplate)) {
      deleteTemporaryTemplate(templateGuid);
    }
  };

  return (
    <Fragment>
      <Flex px={15} height={60} justifyContent='space-between'>
        <Flex>
          { templateName && (
            <Text
              mr={15}
              fontSize={16}
              fontWeight='bold'
              color={darkMainDarkColor}
            >
              {`${G.getWindowLocale('titles:template-name', 'Template Name')}: ${templateName}`}
            </Text>
          )}
          {
            numberObStops &&
            <Box
              mr={15}
              p='5px'
              borderRadius='3px'
              color={whiteColor}
              bg={G.getTheme('colors.mustardYellow')}
            >
              {`${G.getWindowLocale('titles:number-of-stops', 'Number Of Stops')}: ${numberObStops}`}
            </Box>
          }
        </Flex>
      </Flex>
      <SectionsDivider borderColor={lightGreyColor} />
      <PureStyledForm id='some-id' onSubmit={handleSubmit}>
        <Box
          ref={scrollRef}
          overflow='auto'
          height='calc(100vh - 120px)'
          css={scrollableContainerCss3px}
          borderBottom={`1px solid ${lightGreyColor}`}
        >
          <Flex pr={15} width={widthToUse} justifyContent='space-between'>
            <Tabs2
              activeTab={activeTab}
              tabs={scheduleTabsOptions}
              setActiveTab={handleSetActiveTab}
              tabStyles={GC.COMMON_MUI_TAB_STYLES}
              tabsStyles={GC.COMMON_MUI_TABS_STYLES}
            />
          </Flex>
          <ScheduleActiveTab
            {...props}
            widthToUse={widthToUse}
          />
        </Box>
        <FormFooter2
          boxStyles={{ p: 15 }}
          cancelAction={cancelAction}
          submitDisabled={R.lt(routesLength, 1)}
          actionButtons={R.gt(routesLength, 0) ? actionButtons : null}
          submitBtnStyles={{ width: 160, display: hideEditTemplateRoute ? 'none' : 'initial' }}
          submitBtnText={G.getWindowLocale('titles:edit-template-route', 'Edit Template Route')}
        />
      </PureStyledForm>
    </Fragment>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

const enhance = compose(
  connect(mapStateToProps, {
    updateRouteTemplateRequest,
    setExpandedContainerOptions,
    createRouteFromTemplateRequest,
  }),
  withConnectModalAndLoaderActions,
  withFixedPopover,
  withTabs2,
  withAsyncInitialDataOnDidMount,
  withProps(({ asyncInitialData }: Object) => ({
    clos: getClos(asyncInitialData),
  })),
  withAsyncRefTypes,
  withFormik({
    enableReinitialize: true,
    displayName: 'SCHEDULE_ROUTE_FORM',
    validationSchema: ({ clos }: Object) => Yup.lazy(
      (values: Object) => Yup.object().shape(getValidationSchema(clos, values)),
    ),
    mapPropsToValues: (props: Object) => ({ routes: [getNewRow(props.clos)], ...getDefaultTemplateFields(props) }),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal } = props;

      commonSubmit({ ...props, values, editTemplate: true, shouldGoToRouteBuilder: true });

      closeModal();
    },
  }),
  withHandlers({
    handleSaveWithoutClose: (props: Object) => () => {
      const { errors, values, setTouched, temporaryTemplate } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      commonSubmit({ ...props, temporaryTemplate });
    },
    handleUpdateTemplate: (props: Object) => () => {
      const { errors, values, setTouched } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      updateTemplate(props);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRefTypesRequest(this.props.branchGuid, GC.REF_SCOPE_NAME_CLO);
      this.props.validateForm();
    },
  }),
  pure,
);

export default enhance(ScheduleRoute);
