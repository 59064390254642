import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// common
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentUserData } from '../../auth/selectors';
import { makeSelectRoleAvailableList } from '../../role/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../../reference/selectors';
import { makeSelectCurrentBranch, makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover, withComponentDidUpdatePropCallback } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { ListWrapper, IconWrapper, ZOrderWrapper } from '../../../ui';
// feature template-report
import RowActions from './components/row-actions';
import TemplatePageActions from './components/page-actions';
import {
  titlesMap,
  itemNameMap,
  getTableSettingsByListType,
  getFilterParamsByReportType,
  getColumnSettingsByReportType,
  getWritePermissionsByReportType,
  getTitlePanelSettingsByReportType,
} from './settings';
import {
  makeSelectItemList,
  makeSelectListType,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectReportType,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  setListType,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setGlobalFilterValue,
  setQuickFilterParams,
  createReferenceRequest,
  resetListAndPagination,
  exportReportDataRequest,
  toggleTemplateItemDetails,
  removeTemplateItemsRequest,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withProps(({ reportType }: Object) => ({
    filterProps: getFilterParamsByReportType(reportType),
  })),
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleChangeActiveList: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest();
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    getQuickFilteredListRequest: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest();
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) =>
      setReportFromPrompt(data),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest();
      }

      setPromptStatus(false);
    },
    handleListRequest: ({ getItemListRequest }: Object) => () => getItemListRequest(),
    handleCleanFilter: ({ cleanQuickFilter, getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      cleanQuickFilter();
      getItemListRequest();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

      setUsedReport(selectedReport);
      getItemListRequest();
    },
    handleSetGlobalFilter: (props: Object) => (value: string) => {
      const {
        globalFilterValue,
        getItemListRequest,
        setGlobalFilterValue,
        resetListAndPagination,
      } = props;

      if (R.equals(globalFilterValue, value)) return;

      setGlobalFilterValue(value);
      resetListAndPagination();
      getItemListRequest();
    },
  }),
  withPromptModal(),
  withHandlers({
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, item: Object) => {
      const {
        openModal,
        closeModal,
        reportType,
        openLoader,
        closeLoader,
        openFixedPopup,
        closeFixedPopup,
        removeTemplateItemsRequest,
      } = props;

      return openFixedPopup({
        position: 'right',
        el: currentTarget,
        content: (
          <RowActions
            entity={item}
            openModal={openModal}
            reportType={reportType}
            closeModal={closeModal}
            openLoader={openLoader}
            closeLoader={closeLoader}
            guid={G.getGuidFromObject(item)}
            closeFixedPopup={closeFixedPopup}
            active={R.prop(GC.FIELD_ACTIVE, item)}
            removeTemplateItemsRequest={removeTemplateItemsRequest}
            itemName={R.pathOr(null, [R.prop(reportType, itemNameMap)], item)}
          />
        ),
      });
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded, selectedReport }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  withComponentDidUpdatePropCallback({ propName: 'listType', callbackName: 'handleChangeActiveList' }),
  lifecycle({
    componentWillUnmount() {
      const { originalReport, setUsedReport, selectedReport } = this.props;

      if (R.equals(G.getGuidFromObject(originalReport), G.getGuidFromObject(selectedReport))) {
        setUsedReport(originalReport);
      }
    },
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    listType,
    totalCount,
    reportType,
    selectItem,
    pagination,
    reportList,
    filterProps,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleShowListIssues,
    handleTableTitleFilter,
    toggleTemplateItemDetails,
  } = props;

  if (R.not(selectedReport)) return null;

  const renderRightStickedComponent = (item: Object) => (
    <AuthWrapper has={getWritePermissionsByReportType(reportType)}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, item)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const availableItems = R.reject(R.prop('disabled'), R.or(itemList, []));
  const allChecked = G.isAllChecked(availableItems);

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    titleSortValues,
    tableTitleFilters,
    handleShowListIssues,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    withResizableColumns: true,
    renderRightStickedComponent,
    useNewTitleFilterInputs: true,
    toggle: toggleTemplateItemDetails,
    columnSettings: getColumnSettingsByReportType(reportType),
    tableSettings: getTableSettingsByListType({
      listType,
      itemList,
      reportType,
      reportList,
      filterParams,
      selectedReport,
    }),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(R.values(filterProps)),
    ),
  };

  return <Table {...data} />;
};

const getPageWrapperPaddingLeft = (reportType: string) => G.ifElse(
  R.includes(
    reportType,
    [
      GC.CLO_TEMPLATE_REPORT,
      GC.ROUTE_TEMPLATE_REPORT,
      GC.CONTAINER_TEMPLATE_REPORT,
      GC.COMPENSATION_TEMPLATE_REPORT,
    ],
  ),
  '0px',
  null,
);

const TemplateReport = (props: Object) => {
  const {
    listType,
    openModal,
    closeModal,
    openLoader,
    reportType,
    closeLoader,
    filterProps,
    setListType,
    removeTemplateItemsRequest,
  } = props;

  return (
    <ListWrapper p={15} pl={getPageWrapperPaddingLeft(reportType)}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          {...getTitlePanelSettingsByReportType(listType, reportType)}
          type={reportType}
          onSwitch={setListType}
          filterProps={R.values(filterProps)}
          title={G.getWindowLocale(...G.getPropFromObject(reportType, titlesMap))}
          withGlobalSearchFilter={R.equals(reportType, GC.LOCATION_TEMPLATE_REPORT)}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <TemplatePageActions
        openModal={openModal}
        closeModal={closeModal}
        reportType={reportType}
        openLoader={openLoader}
        closeLoader={closeLoader}
        removeTemplateItemsRequest={removeTemplateItemsRequest}
      />
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  listType: makeSelectListType(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  reportType: makeSelectReportType(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  currentUser: makeSelectCurrentUserData(state),
  currentBranch: makeSelectCurrentBranch(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  rolesAvailableList: makeSelectRoleAvailableList(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  selectItem,
  openLoader,
  closeModal,
  setListType,
  closeLoader,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setGlobalFilterValue,
  setQuickFilterParams,
  createReferenceRequest,
  resetListAndPagination,
  exportReportDataRequest,
  toggleTemplateItemDetails,
  removeTemplateItemsRequest,
  changeDefaultReportRequest,
})(enhance(TemplateReport));
