import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { PageTitle } from '../../../components/page-title';
import { ConfirmComponent } from '../../../components/confirm';
import { FormButtons2 } from '../../../components/form-buttons-2';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
import { IntegrationInfo, IntegrationAudit } from '../../carrier/components/general-tab';
import PayToLocationFormGroupTable from '../../fleet/components/pay-to-location-form-group-table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import {
  FormGroupWrapper,
  FieldsetComponent,
} from '../../../forms';
// ui
import { Box, PageHeaderWrapper } from '../../../ui';
// feature service-vendor
import { Documents } from './documents';
import PrimaryLocation from './primary-location';
import { validationSchema, editServiceVendorFieldSettings } from '../settings/fields-settings';
import {
  setDocumentsFilter,
  updateServiceVendorRequest,
  toggleServiceVendorFormGroup,
  deleteServiceVendorDocRequest,
  downloadServiceVendorDocRequest,
  createOrUpdateServiceVendorDocRequest,
} from '../actions';
import {
  makeSelectDocumentList,
  makeSelectServiceVendor,
  makeSelectCollapsedGroup,
  makeSelectDocumentsFilter,
  makeSelectIntegrationInfoList,
  makeSelectServiceVendorConfigs,
  makeSelectIntegrationAuditList,
} from '../selectors';
//////////////////////////////////////////////////

const renderTitle = ({ initialValues }: Object) => {
  const companyName = G.getPropFromObject(GC.FIELD_NAME, initialValues);
  const prefix = G.getWindowLocale('titles:service-vendor', 'Service Vendor');

  return `${prefix} ${companyName}`;
};

const enhance = compose(
  withState('formValues', 'setFormValues', R.propOr({}, 'initialValues')),
  withHandlers({
    handleSetLocation: ({ formValues, updateServiceVendorRequest }: Object) => (values: Object, key: string) =>
      updateServiceVendorRequest({
        updateAndClose: false,
        shouldCloseModal: true,
        values: R.assoc(key, values, formValues),
      }),
    handleToggleFormGroup: ({ toggleServiceVendorFormGroup }: Object) => (fieldName: string) =>
      toggleServiceVendorFormGroup(fieldName),
    handleClickCancel: (props: Object) => () => {
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:confirmation-leave-page',
            'All changes will be lost. Are you sure you want leave page?',
          )}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:leave', 'Leave'),
              action: () => {
                props.closeModal();
                props.history.goBack();
              },
            },
          ],
        },
      };

      props.openModal(modal);
    },
    handleSelectServiceType: ({ change }: Object) => (values: Object) =>
      change(GC.FIELD_SERVICE_TYPES, R.map(R.prop(GC.FIELD_VALUE), values)),
  }),
  branch(
    ({ initialValues, initialDataLoaded }: Object) => (
      R.and(
        R.not(initialDataLoaded),
        G.getGuidFromObject(initialValues),
      )
    ),
    renderNothing,
  ),
  pure,
);

const PageHeader = (props: Object) => (
  <PageHeaderWrapper
    zI='13'
    aI='center'
    position='relative'
    justify='flex-start'
  >
    <PageTitle title={renderTitle(props)} />
  </PageHeaderWrapper>
);

const GeneralDetails = (props: Object) => {
  const { configs, initialValues, setFormValues, handleClickCancel, updateServiceVendorRequest } = props;

  const onSubmit = (values: Object) => updateServiceVendorRequest({ values, updateAndClose: true });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const { values, handleSubmit, isSubmitting } = formik;

  const serviceTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    configs,
    GC.SERVICE_VENDOR_VENDOR_SERVICE_TYPE,
  );

  const buttons = [
    {
      type: 'button',
      action: () => updateServiceVendorRequest({ values }),
      btnText: G.getWindowLocale('actions:update', 'Update'),
    },
  ];

  useEffect(() => {
    setFormValues(R.mergeLeft(values));
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <FieldsetComponent
        {...formik}
        fieldsetWidth={530}
        flexDirection='column'
        fields={editServiceVendorFieldSettings}
        optionsForSelect={{ serviceTypeOptions }}
      />
      <FormButtons2
        buttons={buttons}
        submitting={isSubmitting}
        handleClickCancel={handleClickCancel}
        btnText={G.getWindowLocale('actions:save-and-close', 'Save And Close')}
      />
    </form>
  );
};

const ServiceVendorProfile = (props: Object) => (
  <Box pl={65} pb={60}>
    <PageHeader {...props} />
    <FormGroupWrapper isOpened={R.path(['collapsedGroup', 'profile'], props)}>
      <FormGroupTitleComponent
        withArrowDown={true}
        isOpened={R.path(['collapsedGroup', 'profile'], props)}
        onToggleFormGroup={() => props.handleToggleFormGroup('profile')}
        text={G.getWindowLocale('titles:general-details', 'General Details')}
      />
      <GeneralDetails
        configs={props.configs}
        setFormValues={props.setFormValues}
        initialValues={props.initialValues}
        handleClickCancel={props.handleClickCancel}
        updateServiceVendorRequest={props.updateServiceVendorRequest}
      />
    </FormGroupWrapper>
    <PayToLocationFormGroupTable
      isServiceVendor={true}
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      payToLocation={R.path(['initialValues', GC.SYSTEM_OBJECT_PAY_TO_LOCATION], props)}
      removePayToLocation={() => props.handleSetLocation(null, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
      addOrEditPayToLocation={(values: Object) => props.handleSetLocation(values, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
    />
    <PrimaryLocation
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleSetLocation={props.handleSetLocation}
      handleToggleFormGroup={props.handleToggleFormGroup}
      primaryLocation={R.path(['initialValues', GC.SYSTEM_OBJECT_PRIMARY_LOCATION], props)}
      removePayToLocation={() => props.handleSetLocation(null, GC.SYSTEM_OBJECT_PRIMARY_LOCATION)}
    />
    <Documents {...props} />
    <IntegrationInfo
      entities={props.integrationInfoList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <IntegrationAudit
      entities={props.integrationAuditList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
  </Box>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  documentList: makeSelectDocumentList(state),
  initialValues: makeSelectServiceVendor(state),
  configs: makeSelectServiceVendorConfigs(state),
  collapsedGroup: makeSelectCollapsedGroup(state),
  documentsFilter: makeSelectDocumentsFilter(state),
  integrationInfoList: makeSelectIntegrationInfoList(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  integrationAuditList: makeSelectIntegrationAuditList(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  setDocumentsFilter,
  updateServiceVendorRequest,
  toggleServiceVendorFormGroup,
  deleteServiceVendorDocRequest,
  downloadServiceVendorDocRequest,
  createOrUpdateServiceVendorDocRequest,
})(enhance(ServiceVendorProfile));
