import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

// inspection templates
const resetTemplatesList = createAction('resetTemplatesList');
const getListItemByGuidRequest = createAction('getListItemByGuidRequest');
const getInspectionTemplateRequest = createAction('getInspectionTemplateRequest');
const submitInspectionTemplateRequest = createAction('submitInspectionTemplateRequest');
const submitInspectionTemplateSuccess = createAction('submitInspectionTemplateSuccess');
// template
const updateTemplateFields = createAction('updateTemplateFields');
const editInspectionTemplate = createAction('editInspectionTemplate');
const setCurrentInspectionTemplate = createAction('setCurrentInspectionTemplate');
// sections
const setActiveSection = createAction('setActiveSection');
const updateSectionFields = createAction('updateSectionFields');
const addSectionToTemplate = createAction('addSectionToTemplate');
const deleteSectionFromTemplate = createAction('deleteSectionFromTemplate');
const reorderSectionsInTemplate = createAction('reorderSectionsInTemplate');
// truck/trailer sections
const addVehicleSectionToTemplate = createAction('addVehicleSectionToTemplate');
const deleteVehicleSectionFromTemplate = createAction('deleteVehicleSectionFromTemplate');
const setActiveVehicleSectionComponent = createAction('setActiveVehicleSectionComponent');
// components
const updateComponentFields = createAction('updateComponentFields');
const deleteComponentFromSection = createAction('deleteComponentFromSection');
const addComponentToActiveSection = createAction('addComponentToActiveSection');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // inspection templates
  resetTemplatesList,
  getListItemByGuidRequest,
  getInspectionTemplateRequest,
  submitInspectionTemplateRequest,
  submitInspectionTemplateSuccess,
  // template
  updateTemplateFields,
  editInspectionTemplate,
  setCurrentInspectionTemplate,
  // sections
  setActiveSection,
  updateSectionFields,
  addSectionToTemplate,
  deleteSectionFromTemplate,
  reorderSectionsInTemplate,
  // truck/trailer sections
  addVehicleSectionToTemplate,
  deleteVehicleSectionFromTemplate,
  setActiveVehicleSectionComponent,
  // components
  updateComponentFields,
  deleteComponentFromSection,
  addComponentToActiveSection,
};
