/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { delay } from 'redux-saga';
import { Client } from '@stomp/stompjs';
import { put, take, race, select } from 'redux-saga/effects';
// features
import { makeSelectCurrentUserData } from '../auth/selectors';
import { refreshTokenCatch, refreshTokenRequest, refreshTokenSuccess } from '../auth/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { isNotNilAndNotEmpty } from '../../helpers';
import { isNotProduction } from '../../helpers/env';
// utilities
import { createWSApiUrl } from '../../utilities/http';
// feature sockets
import * as A from './actions';
import * as LC from './constants';
//////////////////////////////////////////////////

const clientTopics = {
  getRouteTel: (data: string) => `/tel/${data}`,
  getUserIFTA: (data: string) => `/user/${data}/ifta`,
  getUserError: (data: string) => `/user/${data}/error`,
  getUserImport: (data: string) => `/user/${data}/import`,
  getCarrierPortal: (data: string) => `/tel/forCarrier/${data}`,
  getUserNotification: (data: string) => `/user/${data}/notification`,
  getUserMassActionError: (data: string) => `/user/${data}/massAction`,
  getAvailableDrivers: (data: string) => `/driver/${data}/availableDrivers`,
  getUserDocumentGeneration: (data: string) => `/user/${data}/documentGeneration`,
  getDriverCardsTel: (masterEnterpriseGuid: string) => `/driverCards/${masterEnterpriseGuid}/tel`,
};

const loadBoardTopics = {
  getLoadBoardResults: (user: string) => `/user/${user}/load-board-load-service/load`,
  getLoadBoardLoginState: (user: string) => `/user/${user}/load-board-service/state/login`,
  getSearchFilterState: (user: string) => `/user/${user}/load-board-service/state/search-filter-state`,
  getPostedShipmentState: (user: string) => `/user/${user}/load-board-service/state/posted-shipment-state`,
  getBookedShipmentState: (user: string) => `/user/${user}/load-board-service/state/committed-shipment-state`,
  getLoadBoardConfigState: (guid: string) => `/enterprise/${guid}/load-board-service/state/load-board-config`,
};

const getConnectedPromise = (client: Object) => new Promise((res: Function) => {
  client.onConnect = (message: string) => {
    if (isNotProduction) console.log(message);

    res(message);
  };

  client.onWebSocketError = () => {
    res(GC.WS_CONNECTION_ERROR);
  };

  client.onWebSocketClose = () => {
    res(GC.WS_CONNECTION_ERROR);
  };
});

const getReconnectDelay = (reconnectDelay: number) => {
  if (R.equals(reconnectDelay, 0)) return 0;

  return R.or(reconnectDelay, 5000);
};

const openStompClient = ({ endpoint, accessToken, reconnectDelay }: Object) => {
  const url = createWSApiUrl(endpoint);

  const client = new Client({
    brokerURL: url,
    connectHeaders: {
      'access-token': accessToken,
    },
    reconnectDelay: getReconnectDelay(reconnectDelay),
    debug: (message: string) => {
      if (isNotProduction) console.log(message);
    },
  });

  client.activate();

  return client;
};

const unsubscribeFromDrivers = (driverGuids: Array, accessToken: string, client: Object) => {
  if (isNotNilAndNotEmpty(driverGuids)) {
    driverGuids.forEach((driverGuid: string) => client.unsubscribe(driverGuid, { 'access-token': accessToken }));
  }
};

const socketReconnects = {};

const areSocketReconnectsOver = (type: string) => {
  if (socketReconnects[type]) {
    socketReconnects[type] += 1;

    if (socketReconnects[type] >= LC.MaxSocketReconnectTries) {
      console.warn(`Can't reconnect ${type} websocket anymore`);

      return true;
    }
  } else {
    socketReconnects[type] = 1;
  }

  return false;
};

const resetSocketReconnectCounter = (type: string) => {
  socketReconnects[type] = 0;
};

function* watchSendStompMessageRequestSaga(client: Object) {
  while (true) { // eslint-disable-line
    const action = yield take(A.socketSendMessageRequest);

    const {
      payload: { destination, body = '', headers = {} },
    } = action;

    client.send(destination, body, headers);
  }
}

const isSocketFailed = (payloadType: string) => R.or(
  R.equals(payloadType, LC.SOCKET_CHANNEL_SOCKET_CLOSE),
  R.equals(payloadType, LC.SOCKET_CHANNEL_SOCKET_ERROR),
);

function* reconnectSocketSaga(type: string, action: Object) {
  yield delay(LC.SocketReconnectDelay);

  if (areSocketReconnectsOver(type)) return;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());

    yield race({
      success: take(refreshTokenSuccess),
      catchAction: take(refreshTokenCatch),
    });
  }

  const userData = yield select(makeSelectCurrentUserData());

  if (G.isNotNil(userData)) {
    yield put(action(userData));
  }
}

function* reconnectSocketSaga2(type: string, action: Object) {
  yield delay(LC.SocketReconnectDelay);

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());

    yield race({
      success: take(refreshTokenSuccess),
      catchAction: take(refreshTokenCatch),
    });
  }

  const userData = yield select(makeSelectCurrentUserData());

  if (G.isNotNil(userData)) {
    yield put(action(userData));
  }
}

function* checkClientSocketDisconnect(client: Object, disconnectAction: Function) {
  const onDisconnect = new Promise((res: Function) => {
    client.onWebSocketError = (payload: Object) => {
      console.warn('///-checkClientSocketDisconnect-onWebSocketError', payload);

      res(payload);
    };

    client.onWebSocketClose = (payload: Object) => {
      console.warn('///-checkClientSocketDisconnect-onWebSocketClose', payload);

      res(payload);
    };
  });

  yield onDisconnect;

  yield put(disconnectAction());
}

function* waitForSocketConnected(client: Object, type: string, action: Function) {
  const message = yield getConnectedPromise(client);

  yield delay(10);

  if (R.equals(message, GC.WS_CONNECTION_ERROR)) {
    yield put(action(false));
  } else {
    yield put(action(true));
  }
}

const getUserGuidFromAction = (action: Object) => R.path(['payload', 'user_guid'], action);

export {
  clientTopics,
  isSocketFailed,
  loadBoardTopics,
  openStompClient,
  reconnectSocketSaga,
  getConnectedPromise,
  reconnectSocketSaga2,
  getUserGuidFromAction,
  waitForSocketConnected,
  unsubscribeFromDrivers,
  areSocketReconnectsOver,
  checkClientSocketDisconnect,
  resetSocketReconnectCounter,
  watchSendStompMessageRequestSaga,
};
