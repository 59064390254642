import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature template-report
import * as A from './actions';
import * as H from './helpers';
import * as C from './constants';
import {
  makeSelectReportType,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

// TODO: remove after testing inspections on prod
import mock from './mock-truck-inspection';

function* inspectionTemplatesListRequestSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportType = yield select(makeSelectReportType());
    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals(`${reportType}Default`, reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const reqBody = {
      ...pagination,
      orderFields,
      currentEnterprise,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.inspectionTemplateList, { data: reqData });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data }));
    } else {
      yield call(G.handleFailResponse, res, 'inspectionTemplatesListRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'inspectionTemplatesListRequestSaga exception');
  }
}

function* getListItemByGuidRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const props = {
      offset: 0,
      limit: 1000,
      currentEnterprise,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: G.getSearchCriteriaByGuid(payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.inspectionTemplateList, { data: props });

    const { data, status } = res;

    yield put(A.setListLoading(false));

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids: [payload]}));
    } else {
      yield call(G.handleFailResponse, res, 'getListItemByGuidRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getListItemByGuidRequestSaga exception');
  }
}

function* submitInspectionTemplateRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const enterpriseGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(G.getGuidFromObject(payload), 'put', 'post');

    const template = H.remapSequenceByIndex(payload);

    const requestData = {
      // ...mock,
      ...template,
      enterpriseGuid,
    };

    const res = yield call(sendRequest, method, endpointsMap.inspectionTemplate, { data: requestData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.setActiveSection(0));
      yield put(A.setCurrentInspectionTemplate(null));

      yield delay(100);
      yield put(A.getListItemByGuidRequest(G.getGuidFromObject(data)));
    } else {
      yield call(G.handleFailResponse, res, 'submitInspectionTemplateRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'submitInspectionTemplateRequestSaga exception');
  }
}

function* getInspectionTemplateRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { currentTemplate, action } = payload;

    const guid = G.getGuidFromObject(currentTemplate);
    const editable = R.prop('editable', currentTemplate);

    const res = yield call(sendRequest, 'get', endpointsMap.inspectionTemplateByGuid(guid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      let template;

      if (R.equals(action, C.INSPECTION_TEMPLATE_COPY)) {
        template = R.compose(
          R.omit([GC.FIELD_GUID, GC.FIELD_NAME]),
          R.assoc('nameStatus', C.INSPECTION_NAME_STATUS_EDIT),
        )(data);
      } else if (R.equals(action, C.INSPECTION_TEMPLATE_VIEW)) {
        template = R.assoc('editable', false, data);
      } else {
        template = R.assoc('editable', R.not(R.equals(false, editable)), data);
      }

      yield put(A.setCurrentInspectionTemplate(template));
    } else {
      yield call(G.handleFailResponse, res, 'getInspectionTemplateRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getInspectionTemplateRequestSaga exception');
  }
}

function* deleteInspectionTemplatesRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.inspectionTemplate, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'deleteInspectionTemplatesRequestSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'deleteInspectionTemplatesRequestSaga exception');
  }
}
// report
const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.INSPECTION_TEMPLATE, A, inspectionTemplatesListRequestSaga, { createUpdateReportSuccessCallback },
);

// visit page
function* visitTemplateInspectionPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_TEMPLATE_INSPECTION_PAGE);

    yield put(openLoader());

    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload: { setUsedReport: true } });

    yield put(A.getItemListRequest({ payload: { openLoader: true } }));

    yield put(closeLoader());

    break;
  }
}

function* templateInspectionWatcherSaga() {
  // visit pages
  yield takeLatest(GC.VISIT_TEMPLATE_INSPECTION_PAGE, visitTemplateInspectionPage);
  // report
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // template
  yield takeLatest(A.getListItemByGuidRequest, getListItemByGuidRequestSaga);
  yield takeLatest(A.getItemListRequest, inspectionTemplatesListRequestSaga);
  yield takeLatest(A.deleteItemRequest, deleteInspectionTemplatesRequestSaga);
  yield takeLatest(A.getInspectionTemplateRequest, getInspectionTemplateRequestSaga);
  yield takeLatest(A.submitInspectionTemplateRequest, submitInspectionTemplateRequestSaga);
}

export default templateInspectionWatcherSaga;
