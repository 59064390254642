import * as R from 'ramda';
import { createPortal } from 'react-dom';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature template-inspection
import * as C from './constants';
import { validateComponent, emptySectionError } from './validation';
import { inspectionTemplateSettings } from './settings/templates-settings';
import vehicleTypesConfig from './settings/vehicle-configs/vehicle-types-config';
//////////////////////////////////////////////////

const VT = R.prop('valueTypeMap', C);

const isVehicleSection = (type: string) => R.includes(type, C.VEHICLE_SECTION_TYPES);

const getActiveVehicleComponent = (section: Object) => {
  const { type, activeVehicleComponentType } = section;


  const sectionConfig = R.prop(type, vehicleTypesConfig);
  const vehicleComponentTypes = R.prop('vehicleComponentTypes', sectionConfig);

  return R.propOr({}, activeVehicleComponentType, vehicleComponentTypes);
};

const getComponentWithOptionValues = (component: Object) => {
  const options = R.prop('options', component);

  if (G.isNilOrEmpty(options)) return component;

  return {
    ...component,
    options: R.map((option: Object) => {
      const {
        getValue,
        valueType = VT.value,
      } = option;

      let value = R.prop(valueType, option);

      if (R.isNil(value)) {
        if (typeof getValue === 'function') value = getValue();
      }

      return R.omit(['getValue'], { ...option, [valueType]: value });
    }, options),
  };
};

const reorderList = (list: Array, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const renderDraggable = (draggableProps: Object, element: HTMLElement) => {
  if (R.pathEq('fixed', ['style', 'position'], draggableProps)) {
    return createPortal(element, document.body);
  }

  return element;
};

const templateNesting = ['sections', 'components'];

const isTemplateInvalid = (currentTemplate: Object) => {
  let success = true;
  let updateTemplate = false;

  const template = R.clone(currentTemplate);

  const validate = (data: Object, level: number) => {
    if (R.equals(level, 2)) {
      const error = validateComponent(data);

      if (G.isNotNilAndNotEmpty(error)) {
        success = false;

        // eslint-disable-next-line no-param-reassign
        data.errorMessage = error;
      } else if (data.errorMessage) {
        updateTemplate = true;

        // eslint-disable-next-line no-param-reassign
        delete data.errorMessage;
      }
    }

    if (G.notEquals(R.prop('nameStatus', data), C.INSPECTION_NAME_STATUS_FILLED)) {
      success = false;
    } else {
      const nestedField = R.prop(level, templateNesting);

      if (R.not(nestedField)) return;

      const nestedList = R.prop(nestedField, data);

      if (G.isNotNilAndNotEmpty(nestedList)) {
        R.forEach((item: Object) => validate(item, R.inc(level)), nestedList);

         // eslint-disable-next-line no-param-reassign
        if (R.equals(level, 1)) delete data.errorMessage;
      } else {
        success = false;

        // eslint-disable-next-line no-param-reassign
        if (R.equals(level, 1)) data.errorMessage = emptySectionError;
      }
    }
  };

  validate(template, 0);

  return G.ifElse(R.and(success, R.not(updateTemplate)), false, template);
};

const remapSequenceByIndex = (currentTemplate: Object) => {
  const template = R.clone(currentTemplate);

  const remap = (entity: Object, level: number) => {
    const nestedField = R.prop(level, templateNesting);

    if (R.not(nestedField)) return;

    const nestedList = R.prop(nestedField, entity);

    if (G.isNotNilAndNotEmpty(nestedList)) {
      G.mapIndexed((item: Object, i: number) => {
        // eslint-disable-next-line no-param-reassign
        item.sequence = i;

        remap(item, R.inc(level));
      }, nestedList);
    }
  };

  remap(template, 0);

  return template;
};

export const filterProps = R.mapObjIndexed(
  ({ name, options, collection, placeholder, filterType = 'string' }: Object, value: string) => {
    let optionsToUse = options;

    if (G.isFunction(options)) optionsToUse = options();

    return ({
      value,
      collection,
      options: optionsToUse,
      [GC.FIELD_TYPE]: filterType,
      placeholder: G.ifElse(
        R.and(R.equals(filterType, 'selectMultiple'), R.isNil(placeholder)),
        { text: 'Choose a status', key: 'titles:choose-a-status' },
        placeholder,
      ),
      [GC.FIELD_NAME]: G.ifElse(
        G.isArray(name),
        // eslint-disable-next-line max-len
        `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}${G.ifElse(R.gt(R.length(name), 2), `: ${G.getWindowLocale(name[2])}`, '')}`,
        G.getWindowLocale(name, '...'),
      ),
    });
  },
  inspectionTemplateSettings,
);

const getSelectedItemsGuids = (list: Array) => R.pluck(GC.FIELD_GUID, R.filter(R.propEq(true, 'selected'), list));

export {
  reorderList,
  renderDraggable,
  isVehicleSection,
  isTemplateInvalid,
  remapSequenceByIndex,
  getSelectedItemsGuids,
  getActiveVehicleComponent,
  getComponentWithOptionValues,
};
