import * as R from 'ramda';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// common
import { openModal, closeModal } from '../../../components/modal/actions';
// components
import { TitlePanel } from '../../../components/title-panel';
import { PageActions } from '../../../components/page-actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// hocs
import { withFixedPopover } from '../../../hocs';
// hooks
import useReport from '../../../hooks/use-report';
// icons
import * as I from '../../../svgs';
// ui
import { ListWrapper, ZOrderWrapper } from '../../../ui';
// feature template-inspection
import * as A from './actions';
import CreateTemplateForm from './components/create-template-form';
import TemplateBuilder from './components/template-builder';
import { filterProps, getSelectedItemsGuids } from './helpers';
import InspectionTemplates from './components/inspection-templates';
import { defaultInspectionTemplate } from './settings/default-data';
import {
  makeSelectItemList,
  makeSelectReportType,
  makeSelectCurrentTemplate,
} from './selectors';
//////////////////////////////////////////////////

const TemplateInspection = ({ openFixedPopup, closeFixedPopup }: Object) => {
  const dispatch = useDispatch();

  const templatesList = useSelector(makeSelectItemList());
  const reportType = useSelector(makeSelectReportType());
  const currentTemplate = useSelector(makeSelectCurrentTemplate());

  const reportProps = {
    type: reportType,
    ...useReport({ A, type: 'templateInspection' }),
  };

  const openTemplateView = useCallback(() => {
    const component = <TemplateBuilder />;
    const modal = G.getFullWindowModalWithContent(component);

    dispatch(openModal(modal));
  });

  const closePopup = useCallback(() => dispatch(closeModal()), []);

  const createNewTemplate = useCallback(() => {
    const component = (
      <CreateTemplateForm
        closeModal={closePopup}
        handleCreateTemplate={(values: Object) => {
          dispatch(A.setCurrentInspectionTemplate({
            ...defaultInspectionTemplate,
            ...values,
          }));

          closePopup();
        }}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {
        width: 400,
        height: 'auto',
        title: G.getWindowLocale('titles:create-template', 'Create Template'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup]);

  const editTemplate = useCallback((guid: string, action: string) => {
    const currentTemplate = R.find(R.propEq(guid, 'guid'), templatesList);

    if (G.isNilOrEmpty(currentTemplate)) return;

    dispatch(A.getInspectionTemplateRequest({ currentTemplate, action }));
  });

  const [builderOpened, setBuilderOpened] = useState(false);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(currentTemplate)) {
      if (R.not(builderOpened)) {
        openTemplateView();
        setBuilderOpened(true);
      }
    } else if (builderOpened) setBuilderOpened(false);
  }, [builderOpened, currentTemplate]);

  const selectedItems = getSelectedItemsGuids(templatesList);

  const deleteTemplates = useCallback(() => {
    dispatch(A.deleteItemRequest(selectedItems));
  }, [selectedItems]);

  const listActions = [
    {
      type: 'massAction',
      action: deleteTemplates,
      // TODO: change inspection permissions later on
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      // permissions: [PC.INSPECTION_TEMPLATE_WRITE],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      icon: I.trash(G.getTheme('colors.white'), '20px', '20px'),
    },
  ];

  // TODO: change inspection permissions later on (createNewTemplate)
  /* <AuthWrapper has={[PC.INSPECTION_TEMPLATE_WRITE]}> */

  return (
    <ListWrapper p={15} pl={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...reportProps}
          withCount={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          withoutQuickFilter={true}
          hiddenRightFilterInfo={false}
          withGlobalSearchFilter={false}
          filterProps={R.values(filterProps)}
          title={G.getWindowLocale('titles:inspection-templates', 'Inspection Templates')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <InspectionTemplates
          {...reportProps}
          itemList={templatesList}
          editTemplate={editTemplate}
          openFixedPopup={openFixedPopup}
          closeFixedPopup={closeFixedPopup}
          filterProps={R.values(filterProps)}
        />
      </ZOrderWrapper>
      <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
        <PageActions
          listActions={listActions}
          count={R.length(selectedItems)}
          shadowColor={G.getTheme('createButton.shadowColor')}
          mainAction={{
            action: createNewTemplate,
            permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
            // permissions: [PC.INSPECTION_TEMPLATE_WRITE],
            text: G.getWindowLocale('titles:create-template', 'Create Template'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

export default withFixedPopover(TemplateInspection);
