// feature template-inspection
import * as C from '../constants';
//////////////////////////////////////////////////

const defaultInspectionTemplate = {
  name: null,
  sections: [],
  description: null,
  signatureRequired: false,
  enableLocationTracking: false,
  nameStatus: C.INSPECTION_NAME_STATUS_FILLED,
};

const defaultSection = {
  name: null,
  components: [],
  expanded: true,
};

export {
  defaultSection,
  defaultInspectionTemplate,
};
