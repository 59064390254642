// helpers/constants
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const rowHeight = 46;

export const templatesTableSettings = {
  rowHeight,
  fontSize: 11,
  cellFontSize: 14,
  titleFontSize: 12,
  maxHeight: '100%',
  checkBoxCellGap: 5,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 130,
  allowSelectItems: true,
  searchableTitles: true,
  titleRowHeight: rowHeight,
  tableRowHeight: rowHeight,
  fixLocalLoaderHeight: true,
  withResizableColumns: false,
  checkBoxCellJustifyContent: 'flex-start',
};

const inspectionTemplatesFields = [
  { name: GC.FIELD_NAME, sequence: 1 },
  { name: GC.FIELD_DESCRIPTION, sequence: 2 },
  { name: GC.FIELD_ENTERPRISE_NAME, sequence: 3 },
  { name: GC.FIELD_SIGNATURE_REQUIRED, sequence: 4 },
  { name: GC.FIELD_ENABLE_LOCATION_TRACKING, sequence: 5 },
  { name: GC.FIELD_CREATED_DATE, sequence: 6 },
  { name: GC.FIELD_CREATED_BY, sequence: 7 },
  { name: GC.FIELD_LAST_MODIFIED_DATE, sequence: 8 },
  { name: GC.FIELD_LAST_MODIFIED_BY, sequence: 9 },
  { name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_NAME}`, sequence: 10 },
  { name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_TYPE}`, sequence: 11 },
  // { name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_DESCRIPTION}`, sequence: 12 }, // Temporary hidden
  { name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_NAME}`, sequence: 12 },
  {
    name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_DESCRIPTION}`,
    sequence: 13,
  },
  {
    name: `${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_VEHICLE_COMPONENT_TYPE}`,
    sequence: 14,
  },
];

export const inspectionTemplatesReport = {
  type: 'INSPECTION_TEMPLATE',
  name: 'inspection-templates-report',
  fields: inspectionTemplatesFields,
};

export const inspectionTemplateSettings = {
  [GC.FIELD_NAME]: {
    name: 'titles:name',
  },
  [GC.FIELD_DESCRIPTION]: {
    name: 'titles:description',
  },
  [GC.FIELD_ENTERPRISE_NAME]: {
    name: 'titles:enterprise-name',
  },
  [GC.FIELD_SIGNATURE_REQUIRED]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:signature-required',
  },
  [GC.FIELD_ENABLE_LOCATION_TRACKING]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:enable-location-tracking',
  },
  [GC.FIELD_CREATED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    name: 'titles:last-modified-by',
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_NAME}`]: {
    collection: true,
    name: ['titles:inspection-sections', 'titles:name'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_TYPE}`]: {
    collection: true,
    name: ['titles:inspection-sections', 'titles:type'],
  },
  // [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_DESCRIPTION}`]: { // Temporary hidden
  //   collection: true,
  //   name: ['titles:inspection-sections', 'titles:description'],
  // },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_NAME}`]: {
    collection: true,
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:name'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_DESCRIPTION}`]: {
    collection: true,
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:description'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_VEHICLE_COMPONENT_TYPE}`]: {
    collection: true,
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:vehicle-component-type'],
  },
};

export const inspectionTemplatesColumnSettings = {
  [GC.FIELD_NAME]: {
    width: '200px',
    searchable: true,
    name: 'titles:name',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: '300px',
    searchable: true,
    name: 'titles:description',
  },
  [GC.FIELD_ENTERPRISE_NAME]: {
    width: '200px',
    searchable: true,
    name: 'titles:enterprise-name',
  },
  [GC.FIELD_SIGNATURE_REQUIRED]: {
    width: '180px',
    type: 'boolean',
    searchable: true,
    name: 'titles:signature-required',
  },
  [GC.FIELD_ENABLE_LOCATION_TRACKING]: {
    width: '180px',
    type: 'boolean',
    searchable: true,
    name: 'titles:enable-location-tracking',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    width: '200px',
    searchable: true,
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: '200px',
    searchable: true,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    width: '200px',
    searchable: true,
    name: 'titles:last-modified-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: '200px',
    searchable: true,
    name: 'titles:last-modified-by',
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_NAME}`]: {
    width: '200px',
    name: ['titles:inspection-sections', 'titles:name'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_TYPE}`]: {
    width: '200px',
    name: ['titles:inspection-sections', 'titles:type'],
  },
  // [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_DESCRIPTION}`]: { // Temporary hidden
  //   width: '150px',
  //   name: ['titles:inspection-sections', 'titles:description'],
  // },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_NAME}`]: {
    width: '300px',
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:name'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_DESCRIPTION}`]: {
    width: '300px',
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:description'],
  },
  [`${GC.FIELD_INSPECTION_SECTIONS}.${GC.FIELD_INSPECTION_COMPONENTS}.${GC.FIELD_VEHICLE_COMPONENT_TYPE}`]: {
    width: '350px',
    name: ['titles:inspection-sections', 'titles:inspection-components', 'titles:vehicle-component-type'],
  },
};
