/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race } from 'redux-saga/effects';
// features
import { refreshTokenCatch, refreshTokenRequest, refreshTokenSuccess } from '../../auth/actions';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature sockets
import * as A from '../actions';
import * as LC from '../constants';
import {
  clientTopics,
  openStompClient,
  getUserGuidFromAction,
  waitForSocketConnected,
  checkClientSocketDisconnect,
  watchSendStompMessageRequestSaga,
} from '../helpers';
//////////////////////////////////////////////////

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    const { payload } = event;

    const { body } = payload;

    const { data, socketDataType } = body;

    switch (socketDataType) {
      case LC.SOCKET_DATA_TYPE_CHAT_MESSAGE:
        yield put(A.socketTelChatMessageReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_LOAD_STATUS:
        yield put(A.socketTelLoadStatusReceived(data));
        break;

      case LC.SOCKET_DATA_TYPE_RATE_STATUS:
        yield put(A.socketTelRateStatusReceived(data));
        break;

      case LC.SOCKET_DATA_TYPE_EVENT_STATUS:
        yield put(A.socketTelEventStatusReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_STATUS_MESSAGE:
        yield put(A.socketTelStatusMessageReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_DOCUMENT:
        yield put(A.socketTelDocumentReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_COST_ALLOCATION:
        yield put(A.socketTelCostAllocationsReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_RATE:
        yield put(A.socketTelRateReceived(body));
        break;

      case LC.SOCKET_DATA_TYPE_CLO_STATUS_TO_TEL:
        yield put(A.socketCloStatusToTelReceived(body));
        break;

      default:
        console.warn(`watchEventChannelMessagesSaga - Unknown socket data type: ${socketDataType}`, body);
        break;
    }
  }
}

const runStompClientSubTel = (
  client: Object,
  { telGuid, accessToken }: Object,
) => eventChannel((emit: Function) => {
  const topic = clientTopics.getRouteTel(telGuid);

  client.subscribe(topic, (message: Object) => {
    const body = JSON.parse(message.body);

    console.info('///-runStompClientSubTel-message-body', body);

    emit({
      type: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
      payload: {
        body,
        type: LC.SOCKET_CHANNEL_TEL_MESSAGE_RECEIVED,
      },
    });
  }, { 'access-token': accessToken });

  return () => client.deactivate({ 'access-token': accessToken });
});

function* reconnectSocketTelSaga(action: Object) {
  yield delay(LC.SocketReconnectDelay);

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());

    yield race({
      success: take(refreshTokenSuccess),
      catchAction: take(refreshTokenCatch),
    });
  }

  const options = {
    endpoint: endpointsMap.routeSocket,
    telGuid: R.path(['payload', 'telGuid'], action),
  };

  if (G.isNotNil(action)) {
    yield put(A.socketTelReconnectRequest(options));
  }
}

function* watchSocketTelConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketTelConnectRequest),
        reconnectAction: take(A.socketTelReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        telGuid: R.path(['payload', 'telGuid'], action),
      };

      const client = yield call(openStompClient, data);

      yield fork(waitForSocketConnected, client, LC.TEL_SOCKET_TYPE, A.socketTelConnected);

      const { payload: connected } = yield take(A.socketTelConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubTel, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketTelDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketTelDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();

        if (R.not(R.pathEq('deactivate', ['payload'], cancel))) {
          yield fork(reconnectSocketTelSaga, action);
        }
      } else {
        yield fork(reconnectSocketTelSaga, action);
      }
    }
  } catch (error) {
    console.log('///////////////////////////////////////', 'catch watchSocketTelConnectRequestSaga');
  }
}

function* socketTelWatcherSaga() {
  yield fork(watchSocketTelConnectRequestSaga);
}

export default socketTelWatcherSaga;
