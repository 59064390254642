/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race } from 'redux-saga/effects';
// features
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// feature sockets
import * as A from '../actions';
import * as LC from '../constants';
import {
  clientTopics,
  openStompClient,
  reconnectSocketSaga2,
  getUserGuidFromAction,
  waitForSocketConnected,
  checkClientSocketDisconnect,
  watchSendStompMessageRequestSaga,
} from '../helpers';
//////////////////////////////////////////////////

const documentGenerationTypes = [
  LC.SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
  LC.SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
];

const downloadFile = ({ url, fileName }: Object) => G.downloadFileFromUrl(url, fileName);

function* watchUserDocumentGeneratedReceivedSaga() {
  while (true) { // eslint-disable-line
    yield delay(2000);

    if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
      window.amousSocketUserDocumentGeneratedReceived = [];
    }

    const spliced = window.amousSocketUserDocumentGeneratedReceived.splice(0, 1)[0];

    if (G.isNotNilAndNotEmpty(spliced)) downloadFile(spliced);
  }
}

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const event = yield take(eventChannel);

    // const { type, payload } = event;

    const { type, payload } = event;

    if (R.includes(type, documentGenerationTypes)) {
      const { body } = payload;

      const { data } = body;

      // const data = R.path(['data', 'data'], payload);

      // TODO: check another approach to handle show created document
      yield put(A.socketUserDocumentGeneratedReceivedSuccess(data));

      if (G.isString(data)) {
        yield call(G.showToastrMessage, 'info', data);
      } else {
        const url = R.path([GC.FIELD_DOCUMENT_URL], data);
        const fileName = R.path([GC.FIELD_DOCUMENT_DOCUMENT_NAME], data);

        if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
          window.amousSocketUserDocumentGeneratedReceived = [];
        }

        window.amousSocketUserDocumentGeneratedReceived.push({ url, fileName });
      }
    }
  }
}

// USER DOCUMENT GENERATION SUBSCRIBE
const runStompClientSubUserDocumentGeneration = (client: Object, data: Object) => eventChannel((emit: Function) => {
  /* eslint-disable no-param-reassign */
  const { userGuid, accessToken, socketChannelType } = data;

  const topic = clientTopics.getUserDocumentGeneration(userGuid);

  client.subscribe(topic, (message: Object) => {
    const body = JSON.parse(message.body);

    console.info('///-runStompClientSubTel-message-body', body);

    emit({
      type: socketChannelType,
      payload: {
        body,
      },
    });
  }, { 'access-token': accessToken });

  return () => {
    // TODO: remove console.log after testing
    console.log('///-client.deactivate', client);

    client.deactivate({ 'access-token': accessToken });
  };
});
// USER DOCUMENT GENERATION SUBSCRIBE

// ROUTE DOCUMENT GENERATION
function* watchSocketRouteDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketRouteDocumentGeneratedRequest),
        reconnectAction: take(A.socketRouteDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.routeSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        socketChannelType: LC.SOCKET_CHANNEL_ROUTE_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.ROUTE_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketRouteDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketRouteDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketRouteDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketRouteDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.ROUTE_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketRouteDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketRouteDocumentGenerationConnectRequestSaga');
  }
}
// ROUTE DOCUMENT GENERATION

// USER DOCUMENT GENERATION
function* watchSocketUserDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketUserDocumentGeneratedRequest),
        reconnectAction: take(A.socketUserDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.userSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        socketChannelType: LC.SOCKET_CHANNEL_USER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.USER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketUserDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketUserDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketUserDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketUserDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.USER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketUserDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketUserDocumentGenerationConnectRequestSaga');
  }
}
// USER DOCUMENT GENERATION

// CARRIER DOCUMENT GENERATION
function* watchSocketCarrierDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketCarrierDocumentGeneratedRequest),
        reconnectAction: take(A.socketCarrierDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.carrierSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        socketChannelType: LC.SOCKET_CHANNEL_CARRIER_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.CARRIER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketCarrierDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketCarrierDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketCarrierDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketCarrierDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.CARRIER_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketCarrierDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketCarrierDocumentGenerationConnectRequestSaga',
    );
  }
}
// CARRIER DOCUMENT GENERATION

// BRANCH DOCUMENT GENERATION
function* watchSocketBranchDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketBranchDocumentGeneratedRequest),
        reconnectAction: take(A.socketBranchDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.branchSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        socketChannelType: LC.SOCKET_CHANNEL_BRANCH_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.BRANCH_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketBranchDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketBranchDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketBranchDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketBranchDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.BRANCH_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketBranchDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketBranchDocumentGenerationConnectRequestSaga',
    );
  }
}
// BRANCH DOCUMENT GENERATION

// FLEET DOCUMENT GENERATION
function* watchSocketFleetDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketFleetDocumentGeneratedRequest),
        reconnectAction: take(A.socketFleetDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        endpoint: endpointsMap.fleetSocket,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        socketChannelType: LC.SOCKET_CHANNEL_FLEET_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.FLEET_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketFleetDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketFleetDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketFleetDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketFleetDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.FLEET_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketFleetDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log('//////////////////////////////////////', 'catch watchSocketFleetDocumentGenerationConnectRequestSaga');
  }
}
// FLEET DOCUMENT GENERATION

// STATISTIC DOCUMENT GENERATION
function* watchSocketStatisticDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketStatisticDocumentGeneratedRequest),
        reconnectAction: take(A.socketStatisticDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        endpoint: endpointsMap.statisticWebsocket,
        socketChannelType: LC.SOCKET_CHANNEL_STATISTIC_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.STATISTIC_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketStatisticDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketStatisticDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketStatisticDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketStatisticDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.STATISTIC_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketStatisticDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketStatisticDocumentGenerationConnectRequestSaga',
    );
  }
}
// STATISTIC DOCUMENT GENERATION

// TEMPLATES DOCUMENT GENERATION
function* watchSocketTemplatesDocumentGenerationConnectRequestSaga() {
  try {
    while (true) { // eslint-disable-line
      const { connectAction, reconnectAction } = yield race({
        connectAction: take(A.socketTemplatesDocumentGeneratedRequest),
        reconnectAction: take(A.socketTemplatesDocumentGeneratedReconnectRequest),
      });

      const action = R.or(connectAction, reconnectAction);

      const data = {
        reconnectDelay: 0,
        userGuid: getUserGuidFromAction(action),
        accessToken: G.getAuthTokenFromSession(),
        endpoint: endpointsMap.templatesWebsocket,
        socketChannelType: LC.SOCKET_CHANNEL_TEMPLATES_DOCUMENT_GENERATION_MESSAGE_RECEIVED,
      };

      const client = yield call(openStompClient, data);

      yield fork(
        waitForSocketConnected,
        client,
        LC.TEMPLATES_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketTemplatesDocumentGeneratedConnected,
      );

      const { payload: connected } = yield take(A.socketTemplatesDocumentGeneratedConnected);

      if (connected) {
        const eventChannel = yield call(runStompClientSubUserDocumentGeneration, client, data);

        const { cancel } = yield race({
          cancel: take(A.socketTemplatesDocumentGeneratedDisconnectRequest),
          task: all([
            call(checkClientSocketDisconnect, client, A.socketTemplatesDocumentGeneratedDisconnectRequest),
            call(watchEventChannelMessagesSaga, eventChannel),
            call(watchSendStompMessageRequestSaga, client),
          ]),
        });

        if (cancel) eventChannel.close();
      }

      yield fork(
        reconnectSocketSaga2,
        LC.TEMPLATES_DOCUMENT_GENERATED_SOCKET_TYPE,
        A.socketTemplatesDocumentGeneratedReconnectRequest,
      );
    }
  } catch (error) {
    console.log(
      '//////////////////////////////////////', 'catch watchSocketTemplatesDocumentGenerationConnectRequestSaga',
    );
  }
}
// TEMPLATES DOCUMENT GENERATION

function* documentGenerationWatcherSaga() {
  yield fork(watchUserDocumentGeneratedReceivedSaga);
  //
  yield fork(watchSocketUserDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketFleetDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketRouteDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketBranchDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketCarrierDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketStatisticDocumentGenerationConnectRequestSaga);
  yield fork(watchSocketTemplatesDocumentGenerationConnectRequestSaga);
}

export default documentGenerationWatcherSaga;
