import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
  pure,
  compose,
  withProps,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import TextComponent from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { workOrderStatusOptions } from '../../../helpers/options';
// hocs
import withAsyncSequence from '../../../hocs/with-async-sequence';
import {
  withAsyncGetUnitIdListByEntityType,
  withAsyncGetUserGeneralListFullName,
  withAsyncGetServiceVendorListAvailabe,
} from '../../../hocs/with-async-get-endpoint-hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
import { scrollableContainerCss4px } from '../../../ui/common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature work-order
import { changeWorkOrderStatusRequest } from '../actions';
import {
  workOrderDefaultValues,
  getWorkOrderFieldSettings,
  workOrdervalidationSchema,
} from '../settings/field-settings';
//////////////////////////////////////////////////

const serviceVendorTypes = R.join(
  ',',
  [GC.SERVICE_VENDOR_TYPE_FLEET_SERVICE, GC.SERVICE_VENDOR_TYPE_FLEET_SELF_SERVICE],
);

const enhance = compose(
  withAsyncGetUnitIdListByEntityType,
  withAsyncGetUserGeneralListFullName,
  withAsyncGetServiceVendorListAvailabe(serviceVendorTypes),
  withState('serviceIssueList', 'setServiceIssueList', []),
  withPropsOnChange(['asyncUnitIdList'], ({ asyncUnitIdList }: Object) => {
    let unitIdOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUnitIdList)) {
      unitIdOptions = G.mapUnitIdGuidObjectPropsToLabelValueObject(asyncUnitIdList);
    }

    return { unitIdOptions };
  }),
  withPropsOnChange(['asyncUserGeneralListFullName'], ({ asyncUserGeneralListFullName }: Object) => {
    let assigneeOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUserGeneralListFullName)) {
      assigneeOptions = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return {
          [GC.FIELD_LABEL]: fullText,
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
        };
      }, asyncUserGeneralListFullName);
    }

    return { assigneeOptions };
  }),
  withPropsOnChange(['serviceIssueList'], ({ serviceIssueList }: Object) => {
    let serviceIssueOptions = [];

    if (G.isNotNilAndNotEmpty(serviceIssueList)) {
      serviceIssueOptions = R.map(({ guid, issueId, status, priority }: Object) => ({
        status,
        priority,
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: issueId,
      }), serviceIssueList);
    }

    return { serviceIssueOptions };
  }),
  withFormik({
    enableReinitialize: true,
    validationSchema: workOrdervalidationSchema,
    mapPropsToValues: ({ initialValues, asyncSequence }: Object) => {
      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_NAME, asyncSequence, workOrderDefaultValues),
        workOrderDefaultValues,
      );

      return G.setInitialFormikValues(defaultFields, initialValues);
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const { entityType, submitAction, asyncSequence } = props;

      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (R.pathEq(asyncSequence, [GC.FIELD_NAME], values)) {
        valuesToUse = R.assoc(GC.FIELD_NAME, null, valuesToUse);
      }

      submitAction({ entityType, values: valuesToUse });
    },
  }),
  withHandlers({
    handleGetServiceIssues: (props: Object) => async (fleetEntityGuid: string, workOrderGuid: string) => {
      const { isTruck, openLoader, closeLoader, setServiceIssueList } = props;

      if (G.isNilOrEmpty(fleetEntityGuid)) setServiceIssueList([]);

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'truckServiceIssueList', 'trailerServiceIssueList'),
        endpointsMap,
      );

      const options = {
        params: { workOrderGuid, [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: fleetEntityGuid },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setServiceIssueList(data);
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetServiceIssues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getAsyncGetUnitIdList, getAsyncUserGeneralListFullName } = this.props;

      getAsyncGetUnitIdList();
      getAsyncUserGeneralListFullName();
    },
  }),
  pure,
);

const enhanceCreate = compose(
  withProps(() => ({
    sequenceConfigName: GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE,
    autogenerateConfigName: GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED,
    configsNamesArray: [GC.FLEET_GENERAL_WORK_ORDER_NAME_SEQUENCE, GC.FLEET_GENERAL_WORK_ORDER_NAME_AUTOGENERATED],
  })),
  withAsyncSequence,
  enhance,
  lifecycle({
    componentDidMount() {
      const { fleetEntityGuid, openedFromFleetProfile, handleGetServiceIssues } = this.props;

      if (G.isTrue(openedFromFleetProfile)) {
        handleGetServiceIssues(fleetEntityGuid);
      }
    },
  }),
);

const enhanceEdit = compose(
  connect(null, { changeWorkOrderStatusRequest }),
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  enhance,
  withHandlers({
    handleChangeStatus: (props: Object) => (entity: Object) => {
      const { openModal, setValues, changeWorkOrderStatusRequest } = props;

      const { guid, truckGuid } = entity;

      const entityType = G.ifElse(G.isNotNilAndNotEmpty(truckGuid), GC.FIELD_TRUCK, GC.FIELD_TRAILER);

      const component = (
        <SelectDropdownForm
          fieldWidth={270}
          optionRequired={true}
          options={workOrderStatusOptions}
          fieldLabel={G.getWindowLocale('titles:status', 'Status')}
          initialValues={{ option: G.getPropFromObject(GC.FIELD_STATUS, entity) }}
          submitAction={(status: Object) => changeWorkOrderStatusRequest({
            entityType,
            values: { guid, status },
            successCallback: (data: Object) => setValues(R.mergeRight(
              entity,
              R.pick([GC.FIELD_VERSION, GC.FIELD_STATUS, GC.FIELD_ACTUAL_COMPLETED_DATE], data),
            )),
          })}
        />
      );

      const modal = G.getDefaultModalOptions(component, G.getEditTitle(['titles:status', 'status']));

      openModal(modal);
    },
    handleGetInitialValues: (props: Object) => async () => {
      const { guid, isTruck, issueGuids, openLoader, closeLoader, setInitialValues } = props;

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'getCurrentTruckWordOrderEndpoint', 'getCurrentTrailerWordOrderEndpoint'),
        endpointsMap,
      )(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues({ ...data, issueGuids });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { guid, fleetEntityGuid, handleGetInitialValues, handleGetServiceIssues } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) {
        handleGetInitialValues();
        handleGetServiceIssues(fleetEntityGuid, guid);
      }
    },
  }),
);

const StatusSection = ({ values, handleChangeStatus }: Object) => {
  const { status, actualCompletedDate } = values;

  const statusInfo = `${G.getEnumLocale(status)} ${
    R.equals(status, GC.STATUS_COMPLETED)
      ? `(${G.convertDateTimeToConfigFormat(actualCompletedDate)})`
      : ''
  }`;

  return (
    (
      <Flex mt={15} pr={15} justifyContent='flex-end'>
        <Box color={G.getTheme('colors.darkGrey')}>
          {G.getWindowLocale('titles:status', 'Status')}:
        </Box>
        <TextComponent
          mx='5px'
          maxWidth={270}
          fontWeight={700}
          title={statusInfo}
          withEllipsis={true}
        >
          {statusInfo}
        </TextComponent>
        <Flex
          cursor='pointer'
          color={G.getTheme('common.linkColor')}
          onClick={() => handleChangeStatus(values)}
          title={G.getWindowLocale('titles:change-status', 'Change Status')}
        >
          {I.renderEditIcon(G.getTheme('icons.iconColor'))}
        </Flex>
      </Flex>
    )
  );
};

const WorkOrderForm = (props: Object) => {
  const {
    guid,
    values,
    isTruck,
    handleSubmit,
    unitIdOptions,
    assigneeOptions,
    handleChangeStatus,
    serviceIssueOptions,
    handleGetServiceIssues,
    openedFromFleetProfile,
    serviceVendorListAvailabeOptions,
  } = props;

  const isEdit = G.isNotNilAndNotEmpty(guid);

  return (
    <Box width={450} overflow='auto' maxHeight='87vh' css={scrollableContainerCss4px}>
      {
        isEdit && <StatusSection values={values} handleChangeStatus={handleChangeStatus} />
      }
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          unitIdOptions={unitIdOptions}
          assigneeOptions={assigneeOptions}
          serviceIssueOptions={serviceIssueOptions}
          handleGetServiceIssues={handleGetServiceIssues}
          serviceVendorListAvailabeOptions={serviceVendorListAvailabeOptions}
          fieldsWrapperStyles={{ p: '25px 15px 0px', justifyContent: 'space-between' }}
          fields={getWorkOrderFieldSettings({ isEdit, isTruck, openedFromFleetProfile })}
        />
        <FormFooter2 boxStyles={{ p: '0px 15px 15px' }} />
      </form>
    </Box>
  );
};

const EditWorkOrderForm = enhanceEdit(WorkOrderForm);
const CreateWorkOrderForm = enhanceCreate(WorkOrderForm);

export {
  EditWorkOrderForm,
  CreateWorkOrderForm,
};
