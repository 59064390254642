import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { openModal, closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { IconWrapper } from '../../../../ui';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const DeleteComponent = ({
  title,
  disabled,
  confirmAction,
  confirmationText,
}: Object) => {
  const dispatch = useDispatch();

  const handleConfirmAction = useCallback(() => {
    confirmAction();

    dispatch(closeModal());
  }, []);

  const handleOpenConfirmation = useCallback(() => {
    if (disabled) return;

    const modal = {
      component: <ConfirmComponent textLocale={confirmationText} />,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: handleConfirmAction,
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    dispatch(openModal(modal));
  }, [disabled]);

  return (
    <IconWrapper
      title={title}
      cursor='pointer'
      onClick={handleOpenConfirmation}
    >
      {I.remove(iconColor)}
    </IconWrapper>
  );
};

export default DeleteComponent;
