import * as R from 'ramda';
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Flex, Text } from '../../../../ui';
// feature template-inspection
import * as C from '../constants';
import {
  setActiveSection,
  addSectionToTemplate,
  addVehicleSectionToTemplate,
  addComponentToActiveSection,
} from '../actions';
import { SelectDropdown } from './option-components';
import { makeSelectActiveSection } from '../selectors';
import { Divider, ComponentUI, SwitchableFlex } from '../ui';
import { availableComponents, componentsSettings } from '../settings/component-configs/components-settings';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const Component = ({
  component,
  onAddComponent,
  currentTemplate,
}: Object) => {
  if (R.equals(component, C.DIVIDER)) return <Divider />;

  const componentProps = R.prop(component, componentsSettings);

  const {
    icon,
    name,
    vehicleType,
    onlyForSection,
    standalone = false,
  } = componentProps;

  const templateVehicleType = R.prop(GC.FIELD_VEHICLE_TYPE, currentTemplate);

  if (R.and(G.isNotNilAndNotEmpty(vehicleType), G.notEquals(vehicleType, templateVehicleType))) return null;

  const sections = R.prop('sections', currentTemplate);
  const noSections = G.isNilOrEmpty(sections);
  const activeSection = R.propOr({}, useSelector(makeSelectActiveSection()), sections);
  const sectionComponents = R.propOr([], 'components', activeSection);
  const activeVehicleComponentType = R.propOr(null, 'activeVehicleComponentType', activeSection);

  const sectionWithVehicleComponentsWasAdded = R.and(
    G.isNotNilAndNotEmpty(vehicleType),
    R.any(R.propEq(component, 'type'), sections),
  );

  const wasComponentAddedToActiveVehiclePart = G.isAllTrue(
    G.isNotNilAndNotEmpty(activeVehicleComponentType),
    R.not(standalone),
    R.any(R.propEq(activeVehicleComponentType, 'vehicleComponentType'), sectionComponents),
  );

  const disabled = G.isAnyTrue(
    R.and(onlyForSection, noSections),
    sectionWithVehicleComponentsWasAdded,
    wasComponentAddedToActiveVehiclePart,
  );

  const explanation = G.ifElse(disabled, G.getWindowLocale('titles:inspection-add-section', 'Add section first'), '');

  const handleClickAdd = useCallback(() => {
    if (disabled) return;

    onAddComponent(component, componentProps);
  }, [component, disabled]);

  return (
    <ComponentUI disabled={disabled} title={explanation} onClick={handleClickAdd}>
      <Flex gap='8px'>
        {icon}
        {name}
      </Flex>
      <Text color={blueColor}>{G.getWindowLocale('titles:inspection-add', 'Add')}</Text>
    </ComponentUI>
  );
};

const SelectSection = ({ currentTemplate }: Object) => {
  const dispatch = useDispatch();

  const { sections } = currentTemplate;

  const activeSection = useSelector(makeSelectActiveSection());

  const dropdownOptions = useMemo(() => R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    G.mapIndexed((section: Object, index: number) => {
      const name = R.prop('name', section);

      if (G.isNilOrEmpty(name)) return null;

      return {
        value: index,
        label: name,
      };
    }),
  )(sections), [sections]);

  const handleSectionChange = useCallback((choice: Object) => {
    const activeSection = R.propOr(0, 'value', choice);

    dispatch(setActiveSection(activeSection));
  }, [sections]);

  return (
    <SelectDropdown mb={15} options={dropdownOptions} value={activeSection} onChange={handleSectionChange} />
  );
};

const ComponentsPanel = ({ viewOnly, currentTemplate }: Object) => {
  const dispatch = useDispatch();

  const onAddComponent = useCallback((component: string, componentProps: Object) => {
    if (viewOnly) return;

    const { config, standalone } = componentProps;

    if (R.equals(component, C.INSPECTION_GENERAL)) dispatch(addSectionToTemplate({ component }));

    if (G.isNotNilAndNotEmpty(R.prop('vehicleType', componentProps))) {
      dispatch(addVehicleSectionToTemplate({ component, componentProps }));
    }

    if (R.not(standalone)) dispatch(addComponentToActiveSection(config));
  }, [viewOnly]);

  const Components = G.mapIndexed((component: string, index: number) => (
    <Component
      component={component}
      key={`${component}-${index}`}
      onAddComponent={onAddComponent}
      currentTemplate={currentTemplate}
    />
  ), availableComponents);

  return (
    <SwitchableFlex
      p={15}
      gap='10px'
      width={300}
      height='100%'
      overflowY='auto'
      disabled={viewOnly}
      borderLeft='1px solid'
      flexDirection='column'
      borderColor={G.getTheme('colors.dark.lightGrey')}
    >
      <SelectSection viewOnly={viewOnly} currentTemplate={currentTemplate} />
      {
        Components
      }
    </SwitchableFlex>
  );
};

export default ComponentsPanel;
