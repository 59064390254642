import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// forms
import { Error } from '../../../../forms/ui';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Flex } from '../../../../ui';
// feature template-inspection
import Options from './options';
import * as C from '../constants';
import { updateComponentFields } from '../actions';
import { NameWithEdit, CheckboxComponent } from './option-components';
//////////////////////////////////////////////////

const ComponentForm = ({ component }: Object) => {
  const {
    id,
    options,
    required,
    description,
    errorMessage,
  } = component;

  const dispatch = useDispatch();

  const toggleRequired = useCallback(() => {
    dispatch(updateComponentFields({
      ...component,
      required: R.not(required),
    }));
  }, [required, component]);

  const setDescription = useCallback((value: string) => {
    dispatch(updateComponentFields({
      ...component,
      description: R.or(value, null),
    }));
  }, [component]);

  return (
    <Flex
      key={id}
      gap={16}
      width='100%'
      flexDirection='column'
      alignItems='flex-start'
    >
      {/* TODO: uncomment after Mobile implementation */}
      {/* <CheckboxComponent
        width='100%'
        name='required'
        value={required}
        setCheckboxValue={toggleRequired}
        label={G.getWindowLocale('titles:inspection-required', 'Required')}
      /> */}
      <NameWithEdit
        width='100%'
        fontSize={16}
        focus={false}
        name={description}
        setInputValue={setDescription}
        status={C.INSPECTION_NAME_STATUS_EDIT}
        label={G.getWindowLocale('titles:inspection-short-description', 'Short Description')}
      />
      { G.isNotNilAndNotEmpty(options) && (
        <Options component={component} />
      )}
      { errorMessage && <Error title={errorMessage}>{errorMessage}</Error> }
    </Flex>
  );
};

export default ComponentForm;
