// TODO: remove after testing inspections on prod

const mock = {
  "name": "Pre-Trip Truck Prod",
  "guid": "7648d109-d1d6-466e-a002-57eb2857b2ec",
  "enterpriseGuid": "d2523b3c-e0e4-42e6-acc2-0292a0acda97",
  "enterpriseName": "Amous International",
  "description": "DO NOT USE IN MOBILE, DELETE OR EDIT!!! Driver's daily vehicle inspection report. This report is a record of the condition of the vehicle and its equipment at the beginning of the trip.",
  "vehicleType": "TRUCK",
  "signatureRequired": true,
  "enableLocationTracking": true,
  "createdDate": "10/09/2024 8:54:26 AM Z",
  "createdBy": "liubanchyk",
  "lastModifiedDate": "10/03/2024 1:44:11 PM Z",
  "lastModifiedBy": "liubanchyk",
  "sections": [
      {
          "name": "Underhood",
          "type": "GENERAL",
          "description": null,
          "photoRequired": false,
          "sequence": 0,
          "components": [
              {
                  "name": "Radiator",
                  "required": true,
                  "description": "A cooling system that regulates engine temperature by transferring heat away from the engine using coolant fluid.",
                  "sequence": 0,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "4bb053ea-c25d-4159-a0ba-dcb660bd0f9e",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "radiatorIcon",
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Engine Oil",
                  "required": false,
                  "description": "Lubricates engine components, reducing friction and preventing overheating to ensure smooth engine operation.",
                  "sequence": 1,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "faf9b181-9380-4cf1-b87b-a75f5c6f3ccb",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "carLubricantIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Fluid Leaks",
                  "required": false,
                  "description": "Any visible signs of leaking fluids (oil, coolant, etc.) that may indicate issues with seals, gaskets, or hoses.",
                  "sequence": 2,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "0e47787b-8fd4-4aad-a885-cb11f82b5a4e",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "oilIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Windshield Washer Fluid",
                  "required": false,
                  "description": "A cleaning solution that helps keep the windshield clear by removing dirt, grime, and bugs.",
                  "sequence": 3,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "93b5453c-12ba-4fa4-9319-a5ae5906e115",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "windshieldIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Power Steering Fluid",
                  "required": false,
                  "description": "Hydraulic fluid that makes steering easier by providing the necessary pressure for the steering mechanism.",
                  "sequence": 4,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "cfcc014f-b140-4551-b0f4-678bb06130f0",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "powerSteeringLiquidIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Belts and Hoses",
                  "required": false,
                  "description": "Essential components that drive key systems like the alternator, water pump, and air conditioning, and transfer fluids throughout the engine.",
                  "sequence": 5,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "df3294ad-7e82-4a1c-8a02-e4293ad150bd",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "beltEngineIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Brake Fluid",
                  "required": false,
                  "description": "A hydraulic fluid that transfers the force applied from the brake pedal to the braking system, ensuring proper brake function.",
                  "sequence": 6,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "18b8154e-b264-4962-b853-d4e2f73c951b",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "brakeFluidIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Coolant Fluid:",
                  "required": false,
                  "description": "A liquid that circulates through the engine and radiator to prevent overheating by dissipating heat.",
                  "sequence": 7,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },

                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "18fa1c87-369f-4e24-8a10-7c589dbf70ad",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "coolantTemperatureIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Battery Life",
                  "required": false,
                  "description": "Refers to the charge capacity of the truck's battery, essential for starting the engine and powering electrical systems.",
                  "sequence": 8,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "DROPDOWN_CHOICES",
                          "type": "OPTION_TYPE_DROPDOWN",
                          "label": "Choices",
                          "additionalLabel": "Add Choice",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "578607f0-1ccb-412d-b7d1-ff67480d3e58",
                                  "value": "100% - 75%",
                                  "failItem": false
                              },
                              {
                                  "id": "d1f3e31d-d0a5-46bf-bd95-cf690ecf4226",
                                  "value": "75% - 50%",
                                  "failItem": true
                              },
                              {
                                  "id": "28387b5a-5e9e-452f-8ee8-8a7fe70d3a28",
                                  "value": "50% - 25%",
                                  "failItem": true
                              },
                              {
                                  "id": "f1281af5-f495-4238-92fe-30d936d7efa2",
                                  "value": "25% - 0%",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "1d7e5a71-6368-45c2-8894-9f9718132045",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "batteryIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "DROPDOWN"
              },
              {
                  "name": "Battery Corrosion / Old Battery",
                  "required": false,
                  "description": "Refers to the buildup of corrosion around the battery terminals, which can impede the battery's performance, or the degradation of an old battery that may no longer hold a charge effectively.",
                  "sequence": 9,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "Pass",
                              "OPTION_TYPE_FAIL_VALUE": "Fail, Work Needed"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "974f567d-3642-4d52-a31c-8f6a3919df6a",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": "carBatteryLVDIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              },
              {
                  "name": "Other Issues Found",
                  "required": false,
                  "description": "Refers to any additional problems discovered during an inspection that are not specifically categorized but could affect the vehicle’s safety or performance.",
                  "sequence": 10,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "PASS_FAIL_VALUES",
                          "type": "OPTION_TYPE_INPUTS",
                          "label": null,
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "valueMap",
                          "valueList": null,
                          "valueMap": {
                              "OPTION_TYPE_PASS_VALUE": "No Issues Found",
                              "OPTION_TYPE_FAIL_VALUE": "Found Issues"
                          },
                          "additionalValueMap": null,
                          "renderItems": [
                              {
                                  "valueField": "OPTION_TYPE_PASS_VALUE",
                                  "label": "Pass Label",
                                  "inputType": null
                              },
                              {
                                  "valueField": "OPTION_TYPE_FAIL_VALUE",
                                  "label": "Fail Label",
                                  "inputType": null
                              }
                          ]
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "8c3c53ef-d220-4d94-8673-dadbfabe1120",
                  "sectionId": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "PASS_FAIL"
              }
          ],
          "id": "e1fb5c91-b7cf-4d51-9af7-c012662d8839",
          "nameStatus": "FILLED",
          "expanded": false,
          "activeVehicleComponentType": null
      },
      {
          "name": "Truck Front",
          "type": "TRUCK_FRONT",
          "description": null,
          "photoRequired": true,
          "sequence": 1,
          "components": [
              {
                  "name": "Identification Lights",
                  "required": false,
                  "description": "These lights are mounted on the truck to improve visibility, especially in low-light conditions, helping to identify the vehicle's presence on the road.",
                  "sequence": 0,
                  "vehicleComponentType": "IDENTIFICATION_LIGHTS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "380d43b1-ab5f-4b4f-872a-89721f278f43",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "3ee17948-3949-4045-b9c8-3c3d2bdc924b",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "51b72430-e8d7-4aab-be31-6fa5982bf3b0",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Clearance Light",
                  "required": false,
                  "description": "A light located on the passenger side that ensures the truck’s outer edges are visible, improving safety during turns and lane changes.",
                  "sequence": 1,
                  "vehicleComponentType": "PASSENGER_SIDE_CLEARANCE_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "0e45be63-7b38-42d4-86a9-ec9f948f86db",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "810c6290-3f7d-46ad-9319-eec8c24ebd69",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "2d3768c4-7c5a-49ae-9cec-99b218c86e4e",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Clearance Light",
                  "required": false,
                  "description": "A light on the driver’s side that helps other vehicles see the truck’s dimensions, enhancing visibility on the road.",
                  "sequence": 2,
                  "vehicleComponentType": "DRIVER_SIDE_CLEARANCE_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "2fd3e8c2-c0cb-431a-9f60-acceac0e23bb",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "8a407fbc-4920-4b12-b001-b68ddee87181",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "0cb583ef-3eb6-4600-8d9f-7a0b397731d7",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Windshield",
                  "required": false,
                  "description": "A transparent glass that protects the driver and passengers from wind, debris, and weather, while providing clear visibility.",
                  "sequence": 3,
                  "vehicleComponentType": "WINDSHIELD",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "1fefe9c7-ff84-40a2-9122-15fe7c2ba8ce",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "52811d19-287a-4fa8-a906-e48e9efd2cb2",
                                  "value": "Crack",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "1f516683-4ba5-4424-bf0f-159b929aab29",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Mirror",
                  "required": false,
                  "description": "A mirror that allows the driver to see the area beside and behind the truck on the passenger side, ensuring safer lane changes.",
                  "sequence": 4,
                  "vehicleComponentType": "PASSENGER_SIDE_MIRROR",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "ba62014d-ba46-4571-81ff-5c9da76a9f3b",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "593c03e8-7a3d-4be6-bdd9-cde95364fc57",
                                  "value": "Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "7e19a6de-dfbb-4364-816e-35e3e8096b50",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Windshield Wiper",
                  "required": false,
                  "description": "A wiper located on the passenger side that clears rain, snow, and dirt from the windshield to maintain visibility.",
                  "sequence": 5,
                  "vehicleComponentType": "PASSENGER_SIDE_WINDSHIELD_WIPER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "b3af619e-a8f3-4cd5-9447-ae80c1f4afc0",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "21e04594-11ea-40cb-a9c2-1128fc1a491a",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              },
                              {
                                  "id": "b6a1da4a-32ea-46ca-a67f-aa903999e563",
                                  "value": "Leaves Streaks",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "a976e858-c958-4b3f-8c51-67b68936b9fb",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Windshield Wiper",
                  "required": false,
                  "description": "A wiper positioned on the driver’s side that clears the windshield for safe driving in adverse weather conditions.",
                  "sequence": 6,
                  "vehicleComponentType": "DRIVER_SIDE_WINDSHIELD_WIPER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "08f25bbf-f76a-473a-8b94-30087a51ee13",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "1d5d8d70-be18-4b65-af56-52009b6b8c59",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              },
                              {
                                  "id": "518b1e75-fc6d-474e-a345-eabecd337783",
                                  "value": "Leaves Streaks",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "36c8ac03-3b11-4ab2-a8b9-c0501a98416e",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Mirror",
                  "required": false,
                  "description": "A mirror located on the driver’s side to assist in viewing traffic and obstacles behind or beside the truck.",
                  "sequence": 7,
                  "vehicleComponentType": "DRIVER_SIDE_MIRROR",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "27784bbd-bf23-498e-8ba9-ab77b1076c2e",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "0f007934-32dd-4e66-87ba-e154db0d9b15",
                                  "value": "Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "432e42c3-26d5-405f-8505-38822f1291d1",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Hood",
                  "required": false,
                  "description": "The cover over the engine compartment that protects the engine from external elements and allows access for maintenance.",
                  "sequence": 8,
                  "vehicleComponentType": "HOOD",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "333826e9-d742-43f0-b9d8-f77275efbcd6",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "13be96da-88db-4837-8dcb-6b8580b578c7",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9f34d3cb-d959-4296-a6f6-b4abc2f76044",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Passenger Side Turn Signal",
                  "required": false,
                  "description": "A signal light on the front passenger side that indicates to others when the truck is turning or changing lanes.",
                  "sequence": 9,
                  "vehicleComponentType": "FRONT_PASSENGER_SIDE_TURN_SIGNAL",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "b81f4885-5185-4a97-b3a1-03fde95ac084",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "d66787e7-2207-4e5c-a403-16bf74d99e68",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "940505a6-a206-42f0-ab86-e05ef632c2aa",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Headlight",
                  "required": false,
                  "description": "A light on the passenger side that illuminates the road ahead during nighttime driving or in poor visibility conditions.",
                  "sequence": 10,
                  "vehicleComponentType": "PASSENGER_SIDE_HEADLIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "27e54aad-873e-4e5e-a376-a211b311929c",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "742d938c-4b1c-4405-8991-96282e33852c",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "a59c8d1b-6645-46a3-b072-da948b212ae5",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Fog Light",
                  "required": false,
                  "description": "A light on the passenger side that helps improve visibility in foggy or misty conditions by casting a wide, low beam.",
                  "sequence": 11,
                  "vehicleComponentType": "PASSENGER_SIDE_FOG_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "68c3384d-4878-49bd-8831-438bd988b682",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "e3d533fa-e1fe-48b7-a629-630293d0502d",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "964db87d-c2ec-45ec-97d3-d8328b57b489",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Passenger Side Marker Light",
                  "required": false,
                  "description": "A small light that helps to mark the width of the vehicle for other drivers, improving visibility at night.",
                  "sequence": 12,
                  "vehicleComponentType": "FRONT_PASSENGER_SIDE_MARKER_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "aac1978f-c2e5-4abc-bb97-6ff105916980",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "dbfcb13f-2ffa-48d2-b92a-ecb38c4893a1",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "00699763-b49f-404e-b356-987e41b6c40b",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Bumper",
                  "required": false,
                  "description": "A protective structure at the front of the truck that absorbs impact in minor collisions and protects critical components.",
                  "sequence": 13,
                  "vehicleComponentType": "FRONT_BUMPER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "33345f9d-ceca-4a1f-b823-7c9147da18eb",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "f5cfe984-c111-4f64-975e-e165a9396b76",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "a924bd88-5253-460e-9ffd-5bd535af2649",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Fog Light",
                  "required": false,
                  "description": "A fog light positioned on the driver's side to enhance visibility in poor weather by cutting through fog or mist.",
                  "sequence": 14,
                  "vehicleComponentType": "DRIVER_SIDE_FOG_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "473974be-6042-4296-b0ed-56c8bd4a98a6",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "44c9c9b0-edf2-4b9e-a285-138b1586085c",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "c7b9d034-8299-483f-97bd-8aab341c7174",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Driver Side Marker Light",
                  "required": false,
                  "description": "A light located on the front driver's side, providing visibility to help mark the edges of the truck.",
                  "sequence": 15,
                  "vehicleComponentType": "FRONT_DRIVER_SIDE_MARKER_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "5083306c-8ade-4805-8998-f5ce15a00113",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "c966e524-3ed8-4d70-ad8a-a067ca937d4b",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "54c39f77-c92c-416b-ac2d-65c135f983b7",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Headlight",
                  "required": false,
                  "description": "A light on the driver’s side that provides illumination on the road, ensuring safe driving during low-light conditions.",
                  "sequence": 16,
                  "vehicleComponentType": "DRIVER_SIDE_HEADLIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "2d9b429b-3081-45b4-9f63-7f1faa90d6e8",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "7407dee1-4673-476e-9019-611b7f8a7234",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9b90f08d-ccc5-4ab5-b9a7-4bca0e5a126c",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Driver Side Turn Signal",
                  "required": false,
                  "description": "A signal light on the front driver’s side that alerts other drivers when the truck is turning or merging.",
                  "sequence": 17,
                  "vehicleComponentType": "FRONT_DRIVER_SIDE_TURN_SIGNAL",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "380a15d4-5b32-47f8-98c9-eef447b0b5b4",
                                  "value": "Don't work",
                                  "failItem": true
                              },
                              {
                                  "id": "25f15502-c417-4401-a635-370cd8c475bc",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "f6daaad7-885d-4f5d-a488-cf3ce9744c8a",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Grille",
                  "required": false,
                  "description": "The front structure of the truck that protects the radiator and engine components while allowing air to pass through for cooling.",
                  "sequence": 18,
                  "vehicleComponentType": "GRILLE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "def7b824-6fd2-4c2a-8547-307dd3cd67e5",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "fef29ff2-3a0f-4804-8e2e-e477845a3958",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "62905972-57c9-4564-867b-45a13d700503",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "a5a6c5fc-7254-47fb-b28c-0267ac6279f4",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "e4629cb6-6936-4998-91c4-7ba2786770ce",
                                  "value": "Scrape",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "d231c400-fc61-4432-9701-1d585a894b82",
                  "sectionId": "f5382f65-faf2-4897-b46f-c39c4478e331",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              }
          ],
          "id": "f5382f65-faf2-4897-b46f-c39c4478e331",
          "nameStatus": "FILLED",
          "expanded": false,
          "activeVehicleComponentType": "FRONT_BUMPER"
      },
      {
          "name": "Truck Right",
          "type": "TRUCK_RIGHT",
          "description": null,
          "photoRequired": true,
          "sequence": 2,
          "components": [
              {
                  "name": "Passenger Side Pillar A",
                  "required": false,
                  "description": "The structural support on the passenger side, located at the front of the vehicle, between the windshield and the front door.",
                  "sequence": 0,
                  "vehicleComponentType": "PASSENGER_SIDE_PILLAR_A",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "1ce923be-daec-4727-af20-d9a2b3241a00",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "4b6e2f48-15b1-4393-bb6c-7582ca267a65",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "742e1e01-1561-4abe-9c22-d3d2a4663679",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Pillar B",
                  "required": false,
                  "description": "A structural component on the passenger side, positioned between the front and rear doors, providing support for the roof and enhancing safety.",
                  "sequence": 1,
                  "vehicleComponentType": "PASSENGER_SIDE_PILLAR_B",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "177b6fbc-a6cd-4ac5-8deb-596973e3abd9",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "264c1e65-672b-4f98-999b-4b427d053dfc",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "031bfa30-77ed-4b58-a469-d56dc9781368",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Sleeper Window",
                  "required": false,
                  "description": "A window located on the passenger side of the sleeper cab, providing ventilation and visibility for the driver’s resting area.",
                  "sequence": 2,
                  "vehicleComponentType": "PASSENGER_SIDE_SLEEPER_WINDOW",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "739ba0ef-0a4f-4e84-8a24-53c5c3a6c759",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "b713dbba-3917-4dbd-b728-e9d4993ca24b",
                                  "value": "Cracked",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "1c0f2d44-0f5e-4fa5-acc4-88898ab81169",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Sleeper Compartment Body",
                  "required": false,
                  "description": "The body panel on the passenger side that encloses the sleeper compartment, contributing to the overall structure of the truck.",
                  "sequence": 3,
                  "vehicleComponentType": "PASSENGER_SIDE_SLEEPER_COMPARTMENT_BODY",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "0edba8cf-63bd-420b-b583-aa6ec18c4261",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "1ce9f9ec-4afc-4e35-beb7-a91c94e79bbb",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "835892da-9f68-448f-8536-99c84ee635dd",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Door Glass",
                  "required": false,
                  "description": "The glass on the passenger side door, offering visibility and protection, while allowing the window to roll up or down.",
                  "sequence": 4,
                  "vehicleComponentType": "PASSENGER_SIDE_DOOR_GLASS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Cff1235",
                                  "failItem": false
                              },
                              {
                                  "id": "692c8472-b902-4f00-9be0-c9d44373320d",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "546d4e48-f459-497b-b98e-ac6b8dbc3b07",
                                  "value": "Crack",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "7b155e0d-9c58-432e-9477-bf14f945273b",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Door Handle",
                  "required": false,
                  "description": "The exterior handle used to open the passenger side door, ensuring easy access to the truck's cabin.",
                  "sequence": 5,
                  "vehicleComponentType": "PASSENGER_SIDE_DOOR_HANDLE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "337f1197-7c42-4b86-bfa9-6bb936239b43",
                                  "value": "Not Functioning Properly",
                                  "failItem": true
                              },
                              {
                                  "id": "6cf7a942-2a08-4206-b7b8-540d9a1bc43e",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "268f8a0d-de5d-430a-b297-b31e60ceb9e9",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Door",
                  "required": false,
                  "description": "The main entry and exit door on the passenger side of the truck, providing access to the cab for passengers.",
                  "sequence": 6,
                  "vehicleComponentType": "PASSENGER_SIDE_DOOR",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "80420ee6-7fc8-4671-b4b9-63572bdb9516",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "ce4b06ad-001f-4251-b17b-b26945b1ff2a",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "3b104869-af6d-4bee-b5aa-06a6b62def07",
                                  "value": "Scrape",
                                  "failItem": true
                              },
                              {
                                  "id": "c540f7a6-2b69-4f58-a15e-b7d0c0735a07",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "3157ad3e-0783-48c1-a089-91df6c0dfb71",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "498bce7c-37e6-4352-92da-e81f09e7c7ed",
                                  "value": "Hole",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "0ff3d0de-8f40-415c-ba72-a9ff4b7eb155",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Battery Box Cover",
                  "required": false,
                  "description": "The cover protecting the battery box on the passenger side, shielding the battery from external elements and ensuring safety.",
                  "sequence": 7,
                  "vehicleComponentType": "PASSENGER_SIDE_BATTERY_BOX_COVER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "dadfb934-9c52-4e85-8904-0e8e5eca143f",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "c698fa2f-c740-44ea-bb83-af21b4254804",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "fd5683ec-1cb3-4971-96e6-07742c7bf4f7",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "242cd4be-48c2-479b-a7c1-be5eca847eba",
                                  "value": "Scrape",
                                  "failItem": true
                              },
                              {
                                  "id": "97403c76-b13a-48a6-8efc-5c91644e820e",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "5925c682-a90b-490c-a264-c32ca36f716a",
                                  "value": "Hole",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "39c62de2-a95e-4513-b98c-9088225002d5",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Rear Drive Axle Wheels / Rims / Valve Caps",
                  "required": false,
                  "description": "The wheels, rims, and valve caps located on the rear drive axle of the passenger side, crucial for ensuring safe driving and air pressure maintenance.",
                  "sequence": 8,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "3addd641-4b49-4988-82cc-5369856cc293",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "85b3644b-a0f1-4a04-9921-492ce1830cfb",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "37dae6be-f4d8-4c60-b48c-ea68d9d3b9b9",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "ca2ee0dd-ad54-4c00-a103-cbc4d56dba85",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Rear Drive Axle Tires",
                  "required": false,
                  "description": "Tires located on the rear drive axle of the passenger side, ensuring traction, stability, and load-bearing capacity.",
                  "sequence": 9,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_TIRES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "6aee3cb0-65e6-42d6-98a4-b2bd6e989540",
                                  "value": "Damage / Nail",
                                  "failItem": true
                              },
                              {
                                  "id": "c97eb203-a677-4073-bd80-4558bdfbb652",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "b47ea93c-c67c-48e8-8f2d-36c101c231cc",
                                  "value": "Shows Tread",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "7f976d87-3632-4639-8bf6-f6cae4fb7215",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Front Drive Axle Wheels / Rims / Valve Caps",
                  "required": false,
                  "description": "Wheels, rims, and valve caps located on the front drive axle of the passenger side, essential for proper handling and safety.",
                  "sequence": 10,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "b5a94d3a-2aa7-40d8-a292-612bd8bb56ef",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "1dae9a60-d30a-48b2-b480-3695a8968ccf",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "4c31de83-79d6-4c9b-9ed6-8f2705c4b6bc",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "c1c64f00-150e-40bb-a7d1-74215def4be5",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Front Drive Axle Tires",
                  "required": false,
                  "description": "Tires located on the front drive axle of the passenger side, essential for handling and distributing the vehicle’s weight.",
                  "sequence": 11,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_TIRES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "04234ce1-5a93-437a-8119-c4bb2d68b578",
                                  "value": "Damage / Nail",
                                  "failItem": true
                              },
                              {
                                  "id": "55f3d01d-a777-41df-b861-63c9ddf7376a",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "7767191e-f0c2-4d03-bae4-82dbbd4e7c6c",
                                  "value": "Shows Tread",
                                  "failItem": false
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "6a8fe475-06d8-45cc-aa34-56370d353e36",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Fuel Tank & Lines",
                  "required": false,
                  "description": "The fuel storage tank and the associated lines on the passenger side, responsible for holding and transferring fuel to the engine.",
                  "sequence": 12,
                  "vehicleComponentType": "PASSENGER_SIDE_FUEL_TANK_AND_LINES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "83c9568e-cad9-4057-883c-e5d4c5dece11",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "0cf6f560-7a39-4563-9e2e-febdc8f82eb2",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "bc876706-a69d-47da-b9e5-520546e21403",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Passenger Side Wheel / Rim / Valve Cap",
                  "required": false,
                  "description": "The wheel, rim, and valve cap located on the front passenger side, responsible for supporting the vehicle's weight and ensuring a smooth ride.",
                  "sequence": 13,
                  "vehicleComponentType": "FRONT_PASSENGER_SIDE_WHEEL_RIM_VALVE_CAP",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "b99bab10-1749-4e8e-996f-b209eb184512",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "8c654f1d-9d3c-4036-858f-2ccda32cdc19",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "c1672a65-cd88-47b8-a13a-c50238c025e1",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "b62e177f-a09b-41cc-8a7d-473364f81703",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Passenger Side Tire",
                  "required": false,
                  "description": "The tire located on the front passenger side, responsible for steering, traction, and load support.",
                  "sequence": 14,
                  "vehicleComponentType": "FRONT_PASSENGER_SIDE_TIRE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "5333ac61-de6a-4e0a-827e-5cb2bf1b0881",
                                  "value": "Damage / Nail",
                                  "failItem": true
                              },
                              {
                                  "id": "155ef0a6-7a7f-4c1f-804d-4b89971b2c9e",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "de07748c-fbe0-4902-be35-fbd7a125a2c5",
                                  "value": "Shows Tread",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "e4fdb8e1-343f-44c2-afda-7d6587ae05a6",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Fender",
                  "required": false,
                  "description": "A protective covering over the wheel on the passenger side, preventing debris from being thrown into the air and protecting the vehicle's body.",
                  "sequence": 15,
                  "vehicleComponentType": "PASSENGER_SIDE_FENDER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ce887369-2368-410d-b06a-ed48d6980111",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "1999f595-6c4e-49e2-b803-0d1798cb121c",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "7eddd246-1397-42e4-8c1b-ac05b59530bd",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "bc151802-4b24-43b4-bdc0-8d8475ab5752",
                  "sectionId": "b00fb7ec-6291-4476-a725-0227f1734572",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              }
          ],
          "id": "b00fb7ec-6291-4476-a725-0227f1734572",
          "nameStatus": "FILLED",
          "expanded": false,
          "activeVehicleComponentType": "PASSENGER_SIDE_PILLAR_A"
      },
      {
          "name": "Truck Back",
          "type": "TRUCK_BACK",
          "description": null,
          "photoRequired": true,
          "sequence": 3,
          "components": [
              {
                  "name": "Driver Side Reflective Tape (Cab)",
                  "required": false,
                  "description": "Reflective tape located on the driver’s side of the cab, enhancing visibility during low-light conditions for safety.",
                  "sequence": 0,
                  "vehicleComponentType": "DRIVER_SIDE_REFLECTIVE_TAPE_CAB",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "fd016bf5-82c3-4994-b990-03714b819e28",
                                  "value": "Checked and Ok",
                                  "failItem": false
                              },
                              {
                                  "id": "fdcb6ae7-f23a-40ab-ad83-07cd3bcee0ce",
                                  "value": "Not Clean",
                                  "failItem": true
                              },
                              {
                                  "id": "7a8adaaa-ed71-4d64-81e6-62e354095eae",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9957eea9-29c5-40bf-875c-32e56a99c040",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Reflective Tape (Cab)",
                  "required": false,
                  "description": "Reflective tape on the passenger side of the cab, designed to make the truck more visible to other drivers, especially at night.",
                  "sequence": 1,
                  "vehicleComponentType": "PASSENGER_SIDE_REFLECTIVE_TAPE_CAB",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "bdc3c9f0-8c75-4d36-8911-67e99067577f",
                                  "value": "Checked and Ok",
                                  "failItem": false
                              },
                              {
                                  "id": "9fe254db-2a82-4893-92f9-975b7e00a566",
                                  "value": "Not Clean",
                                  "failItem": true
                              },
                              {
                                  "id": "a7e55b68-1266-489e-aa15-065ffd9c1661",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9921fdb5-c115-4614-a86a-886676a560aa",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Headache Rack",
                  "required": false,
                  "description": "A protective barrier installed behind the cab, preventing cargo from shifting forward into the truck's cab and offering additional safety.",
                  "sequence": 2,
                  "vehicleComponentType": "HEADACHE_RACK",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "bb3decdf-242d-4a2a-ad9d-4604f7a87304",
                                  "value": "Checked and Ok",
                                  "failItem": false
                              },
                              {
                                  "id": "90e9967d-fea9-457f-9ecd-a53b348811c1",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "d1e3519e-52aa-4c15-b6d1-380d3d9f2c0a",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "c9cd3e96-f273-48b4-a317-aac61ffb26a0",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "d433b5e4-10b8-48fb-829b-a09df2203347",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "0e0d0b5c-2bb6-4341-825e-f2c96c917eab",
                                  "value": "Scrape",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "4ed08c3b-0831-42c7-a857-8fe4a68ff043",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Fifth Wheel",
                  "required": false,
                  "description": "A coupling device that connects the truck to the trailer, allowing the trailer to pivot during turns and offering secure towing.",
                  "sequence": 3,
                  "vehicleComponentType": "FIFTH_WHEEL",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "665bfd8d-61c0-46ca-a6cf-e737801a9e11",
                                  "value": "Checked and Ok",
                                  "failItem": false
                              },
                              {
                                  "id": "9a771985-e745-4e6b-8f85-32c7bd4d9b6a",
                                  "value": "Not Functioning Properly",
                                  "failItem": true
                              },
                              {
                                  "id": "e31a5c26-838c-4281-bfab-9eb79b4728f9",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "eddb973f-b7e0-4383-9280-6fe82b8a9dac",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Reverse Light",
                  "required": false,
                  "description": "A light on the driver’s side of the truck that illuminates when reversing, ensuring visibility for others when the vehicle is backing up.",
                  "sequence": 4,
                  "vehicleComponentType": "DRIVER_SIDE_REVERSE_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "5c1181f7-c834-4904-b2ba-d8ea79dab127",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "9e1a6c6e-bc45-436b-a8db-b1623b65c3e3",
                                  "value": "Doesn't work",
                                  "failItem": true
                              },
                              {
                                  "id": "bca408e2-a25c-409a-88c9-2b2aaf5a1ab7",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "d1b636aa-87df-4a82-b236-4895279db6c9",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Reverse Light",
                  "required": false,
                  "description": "A light on the passenger side that activates when the vehicle is in reverse, helping ensure safe backing by providing illumination.",
                  "sequence": 5,
                  "vehicleComponentType": "PASSENGER_SIDE_REVERSE_LIGHT",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "cacf3ff5-9779-4cb8-b085-55638b45525b",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "3165a6c5-69bc-42a2-994e-f0fa83a04d5e",
                                  "value": "Doesn't work",
                                  "failItem": true
                              },
                              {
                                  "id": "6ed4a79f-df7d-48c6-b64f-c2f3f3d4f7de",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "17cf039c-3a97-4d06-8858-38ebb35b852d",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Reflective Tape",
                  "required": false,
                  "description": "Reflective tape on the driver’s side of the truck, improving visibility and safety by reflecting headlights at night.",
                  "sequence": 6,
                  "vehicleComponentType": "DRIVER_SIDE_REFLECTIVE_TAPE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "aa37f887-75f5-4bb4-a2e2-a4c048cbcd23",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "550fd4bd-52d5-421e-9bb8-7bd0281acff5",
                                  "value": "Not Clean",
                                  "failItem": true
                              },
                              {
                                  "id": "8f696e7f-178a-42a7-a2cf-41568e30baa8",
                                  "value": "Not Proper Color",
                                  "failItem": true
                              },
                              {
                                  "id": "aa3d6166-0a9e-4c1b-adad-d27a4f65a4d2",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9199acb0-d498-480f-9595-e77396d8f8ef",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Turn Signal:",
                  "required": false,
                  "description": "A signal light located at the rear on the driver’s side, indicating when the truck is turning or changing lanes.",
                  "sequence": 7,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_TURN_SIGNAL",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "2ea93b53-a2c2-4396-b93e-b794ced134cb",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "cef29495-7b40-4c78-b9c4-ef0d46c703f5",
                                  "value": "Doesn't Work",
                                  "failItem": true
                              },
                              {
                                  "id": "49feff9a-2c8d-4cbd-824b-c075420d3c9a",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "80656e40-f781-4916-9a8f-f9fd8b34b328",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Turn Signal",
                  "required": false,
                  "description": "A signal light at the rear on the passenger side that alerts other drivers when the vehicle is making a turn or lane change.",
                  "sequence": 8,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_TURN_SIGNAL",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "e3f506e8-8439-4dfb-871e-3f789ee9066e",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "d2edb628-ef03-4dca-9cb7-4c1367da4fa9",
                                  "value": "Doesn't Work",
                                  "failItem": true
                              },
                              {
                                  "id": "cdaee11f-2020-411d-a082-7ea6b3d101c4",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "332cd7a1-18f8-4fa3-9273-8b61a349dfd2",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Reflective Tape",
                  "required": false,
                  "description": "Reflective tape on the passenger side, designed to improve visibility of the vehicle from the side, especially at night.",
                  "sequence": 9,
                  "vehicleComponentType": "PASSENGER_SIDE_REFLECTIVE_TAPE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "33839d0c-752a-426b-98ef-e27f0dae8c45",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "e13d6fbd-62e7-48a3-ad24-a8a117e68892",
                                  "value": "Not Clean",
                                  "failItem": true
                              },
                              {
                                  "id": "cce97d29-9cac-48da-b58f-242b43d0dcc6",
                                  "value": "Not Proper Color",
                                  "failItem": true
                              },
                              {
                                  "id": "d96054bb-ad46-4003-b510-d1b07adb170e",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "e7988263-08a1-44e9-8a35-aad6bb3ebd14",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Brake / Tail Light & Reflectors",
                  "required": false,
                  "description": "The combination of brake light, tail light, and reflectors on the driver’s side, ensuring visibility when braking and driving in low light.",
                  "sequence": 10,
                  "vehicleComponentType": "DRIVER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "bc4b6b5d-cabe-444e-bd06-f8b07e35c188",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "11215168-79e6-4a51-a96b-63dba5255563",
                                  "value": "Doesn't Work",
                                  "failItem": true
                              },
                              {
                                  "id": "9fcf85d9-e929-401e-9a5f-7f995a2087ab",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "84b7cd97-a296-4b30-9578-6adc035e8e65",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Passenger Side Brake / Tail Light / Reflectors",
                  "required": false,
                  "description": "The combination of brake light, tail light, and reflectors on the passenger side, improving safety by ensuring visibility when stopping or at night.",
                  "sequence": 11,
                  "vehicleComponentType": "PASSENGER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "b6e8c749-7c96-4d04-879e-2ec691e9f07d",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "8d8b86a1-64ae-4af7-a099-eaa954f68cb0",
                                  "value": "Doesn't Work",
                                  "failItem": true
                              },
                              {
                                  "id": "c4909a0e-f86c-44e0-a692-20dc2632bf5f",
                                  "value": "Cracked or Broken",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "3948ada5-647d-495a-92ad-099d972cddcd",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear-End Protection",
                  "required": false,
                  "description": "A protective bumper or bar at the rear of the truck designed to prevent damage during low-speed collisions and protect the vehicle’s structure.",
                  "sequence": 12,
                  "vehicleComponentType": "REAR_END_PROTECTION",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "361a7036-a659-4d7b-bd9f-85e0148bcfe4",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "f335f5f9-5d12-458e-8f95-952d09fd7e47",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "dbae21e3-62d3-4b62-bd0e-112dbd3e9f66",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "994d632f-3bfc-4e5d-af5c-399f943d3cd2",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "50fe6413-88cd-4521-a539-f61f6087bc39",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "223fe2b3-26b6-4980-9d26-5171c27a978b",
                                  "value": "Scrape",
                                  "failItem": true
                              },
                              {
                                  "id": "ce2ce977-b1ff-4d7e-bb13-9b6c2c9e7781",
                                  "value": "Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "6aeca638-0ea9-4599-9962-2d3a5803d8cc",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Mud Flap",
                  "required": false,
                  "description": "A flap located behind the rear tires on the driver’s side to prevent mud, debris, and water from being thrown up by the tires.",
                  "sequence": 13,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_MUD_FLAP",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "8fe69586-96b3-4502-a5d3-e4d741549444",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "71fcfce5-6d79-4bd9-bad3-25edb3a0a59a",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "0ad2f827-39e6-4622-ad10-fa106c29096c",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "875717a5-5a52-4dbc-8b96-09a72dd03d42",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Passenger Side Mud Flap",
                  "required": false,
                  "description": "A mud flap positioned behind the rear tires on the passenger side to reduce spray and debris from being projected onto the road or other vehicles.",
                  "sequence": 14,
                  "vehicleComponentType": "REAR_PASSENGER_SIDE_MUD_FLAP",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "572b8dea-dfb7-44a6-830c-6b4cb923c5e5",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "1bd9a4dc-73e7-4f66-a07c-4cf0ba5d64dd",
                                  "value": "Missing",
                                  "failItem": true
                              },
                              {
                                  "id": "5cfbcad5-8e76-4cd9-aa75-75ca8fb6cf82",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "6b379f3f-2a53-4a4e-9cd0-49e866a9c11a",
                  "sectionId": "d3db8192-5559-4b51-b168-dddcd6cf5258",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              }
          ],
          "id": "d3db8192-5559-4b51-b168-dddcd6cf5258",
          "nameStatus": "FILLED",
          "expanded": false,
          "activeVehicleComponentType": "REAR_PASSENGER_SIDE_MUD_FLAP"
      },
      {
          "name": "Truck Left",
          "type": "TRUCK_LEFT",
          "description": null,
          "photoRequired": true,
          "sequence": 4,
          "components": [
              {
                  "name": "Roof",
                  "required": false,
                  "description": "The topmost part of the vehicle, providing protection from weather and maintaining the structural integrity of the truck's cabin.",
                  "sequence": 0,
                  "vehicleComponentType": "ROOF",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ea538787-cae2-45a0-913e-018001a45d57",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "bd47977e-9879-4dba-9822-bb49447a2192",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "a91649ca-ad56-4ffb-be88-0b6df68057ab",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "dff827b8-e39b-420e-b759-ff42e846c7b4",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "dc21b6d7-9c97-4baf-a85e-d0399950bace",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "9d4e17e5-809b-4d8a-bca5-3242750714b5",
                                  "value": "Scrape",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "af83ae17-bd25-4d49-82da-9da459548b68",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Pillar A",
                  "required": false,
                  "description": "The structural support on the driver’s side, positioned between the windshield and the front door, providing stability and crash protection.",
                  "sequence": 1,
                  "vehicleComponentType": "DRIVER_SIDE_PILLAR_A",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "8cdd2454-5698-4631-a3d7-bf2d60ee2758",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "6c85f93a-179b-4588-ade0-f69066576751",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "6a2816fd-bdfd-4fef-b413-c62210daeacc",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "dd7d6868-a13e-41ef-9207-3c5076b5741a",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Pillar B",
                  "required": false,
                  "description": "A support structure on the driver’s side, located between the front and rear doors, enhancing the vehicle's frame strength.",
                  "sequence": 2,
                  "vehicleComponentType": "DRIVER_SIDE_PILLAR_B",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "f47614c6-399d-469b-8d45-e989090b04d8",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "12aa5f63-6b91-41fb-9f8a-c53b51232fae",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "f8513232-fdfb-4b99-88da-eb0b4e198643",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "8003ad0e-3ed7-4f83-829c-c40a41bb4473",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Sleeper Window",
                  "required": false,
                  "description": "A window located on the driver’s side of the sleeper cabin, providing visibility and ventilation for the driver’s resting area.",
                  "sequence": 3,
                  "vehicleComponentType": "DRIVER_SIDE_SLEEPER_WINDOW",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "e7e3defe-1b2c-4756-a9fb-1f7b26aa3116",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "219a0c0c-0d4c-47a4-892b-374070f2345d",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "aeb847a7-108a-44cc-bf5e-4c4044a6e895",
                                  "value": "Crack",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "fc6c289a-bae2-4bed-8a0e-6d59b34d3db0",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Door Glass",
                  "required": false,
                  "description": "The glass on the driver’s side door, which offers protection and visibility while allowing the window to be opened or closed.",
                  "sequence": 4,
                  "vehicleComponentType": "DRIVER_SIDE_DOOR_GLASS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "065795c5-077a-4b30-8845-4846199a18c6",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "8bc84675-7419-444f-98db-9188d9f4b506",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "492b2142-42dd-4dbd-b452-ad3a4b300a6f",
                                  "value": "Crack",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "8e283d31-d707-4e76-8d6c-013978521af3",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Door Handle",
                  "required": false,
                  "description": "The handle used to open and close the driver’s side door, providing secure access to the vehicle’s cabin.",
                  "sequence": 5,
                  "vehicleComponentType": "DRIVER_SIDE_DOOR_HANDLE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "5621252f-b52d-4ca2-8b80-de1670a80972",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "a6179545-14aa-4e98-be7b-1716742f1b56",
                                  "value": "Not Functioning Properly",
                                  "failItem": true
                              },
                              {
                                  "id": "d8ee0740-4c79-4dca-8b2f-f14d17ad9b8a",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "65837546-4716-4f07-ab0b-ef927a234e2b",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Door",
                  "required": false,
                  "description": "The main door on the driver’s side, allowing entry and exit from the vehicle's cabin.",
                  "sequence": 6,
                  "vehicleComponentType": "DRIVER_SIDE_DOOR",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "64548b2e-cce3-4e02-814d-cf53614448e5",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "6e9c0391-303f-4eae-9374-f7d9c4d4ef8b",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "d86502e3-7290-4502-88ca-3dd180c9f9e9",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "3091b744-c2d0-4a10-8428-dc5b320f72d2",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "3ffb4836-35a9-4134-8d75-c14577b3bed6",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "af20d675-7afb-4371-b1a2-1d6d0145077e",
                                  "value": "Scrape",
                                  "failItem": true
                              },
                              {
                                  "id": "98a0d905-a622-483e-ae77-cced88ae5c8d",
                                  "value": "Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "06b5f39b-2736-408c-9358-3c6930656e28",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Fender",
                  "required": false,
                  "description": "A protective covering over the wheels on the driver’s side, designed to prevent debris from being thrown into the air.",
                  "sequence": 7,
                  "vehicleComponentType": "DRIVER_SIDE_FENDER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "b7fded3e-d299-48ef-afbe-a2de241d8df6",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "2c18db24-bae9-411e-8a69-5bf579ef29e9",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "03e119a3-6391-45c9-af40-6823d5a61a15",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "8e64ba95-c85c-49cb-9834-e15ee6e2e9b5",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Sleeper Compartment Body",
                  "required": false,
                  "description": "The exterior body of the sleeper compartment on the driver’s side, providing structure and protection for the sleeper area.",
                  "sequence": 8,
                  "vehicleComponentType": "DRIVER_SIDE_SLEEPER_COMPARTMENT_BODY",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "fff9ef78-e13c-423c-b665-e04d0cf3f027",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "8c21a35c-06da-4ed2-88f4-8149bbf0f4b1",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "fd29c589-bf6a-4468-a954-bc6795caee8f",
                                  "value": "Scratches",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "35a5b62d-db24-470c-854a-f997b82f741e",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Fuel Tank and Lines",
                  "required": false,
                  "description": "The fuel storage tank and associated fuel lines located on the driver’s side, responsible for storing and supplying fuel to the engine.",
                  "sequence": 9,
                  "vehicleComponentType": "DRIVER_SIDE_FUEL_TANK_AND_LINES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "9deedcfc-e450-42e8-9f8e-b427aedd9498",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "40a24216-7b55-4a60-9644-9c7ba863eef5",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "e3e8c4f0-96f1-49c4-8e9a-5b4aa610c662",
                                  "value": "Damage",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "1c6c4cdd-4175-474d-bbbb-cf4aa87c454a",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Driver Side Wheel / Rim / Valve Cap",
                  "required": false,
                  "description": "The wheel, rim, and valve cap on the front driver’s side, ensuring smooth movement and proper air pressure.",
                  "sequence": 10,
                  "vehicleComponentType": "FRONT_DRIVER_SIDE_WHEEL_RIM_VALVE_CAP",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "94a215b4-6039-4ea2-9aac-911f0027b865",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "9b940640-0892-4e21-b758-74ba062b3740",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "4c098236-5452-448d-b082-9132a3192873",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "765f726a-7271-4229-abae-1aac96352abd",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "59790343-d395-4ff1-9486-08b503bf047d",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Front Driver Side Tire",
                  "required": false,
                  "description": "The tire located on the front driver’s side, providing traction, stability, and load-bearing capacity.",
                  "sequence": 11,
                  "vehicleComponentType": "FRONT_DRIVER_SIDE_TIRE",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "2abc24ac-3827-45c6-bd85-f6de5100ae85",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "ce8cd8c0-0d47-4b60-a845-bca2f063b6a9",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "ca9c1bfd-10a6-4023-8d93-e4641d01c7c7",
                                  "value": "Shows Tread",
                                  "failItem": true
                              },
                              {
                                  "id": "6bdfaa06-14f9-49f0-bbce-d400e3ee1ecd",
                                  "value": "Damage or Nail",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "d3a136c1-6f65-48cd-ad89-d84dc59eb2f0",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Driver Side Battery Box Cover",
                  "required": false,
                  "description": "A cover that protects the battery box on the driver’s side, safeguarding the battery from external elements.",
                  "sequence": 12,
                  "vehicleComponentType": "DRIVER_SIDE_BATTERY_BOX_COVER",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "5ade9d6d-5132-422e-ab2c-c10f4327fed2",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "cf8d842d-d743-41d0-85a1-e62a132a61b5",
                                  "value": "Chip",
                                  "failItem": true
                              },
                              {
                                  "id": "97ac3dee-c82f-4561-9220-68686e969510",
                                  "value": "Dents",
                                  "failItem": true
                              },
                              {
                                  "id": "3f84a7c1-4b13-449a-b36a-f62983e7531f",
                                  "value": "Hole",
                                  "failItem": true
                              },
                              {
                                  "id": "d74e3d2f-7fa6-4632-80ad-c15b8060d7a6",
                                  "value": "Crack",
                                  "failItem": true
                              },
                              {
                                  "id": "f3545173-5b96-42b6-84f4-e76f4f9b4e31",
                                  "value": "Scrape",
                                  "failItem": true
                              },
                              {
                                  "id": "f51bd4ee-9d91-4ace-9643-30649b0c5fd5",
                                  "value": "Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "2d589634-db6e-4a1e-8d13-13c1d000705d",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Front Drive Axle Tires",
                  "required": false,
                  "description": "The tires on the rear driver’s side front axle, essential for handling, traction, and supporting the vehicle's load.",
                  "sequence": 13,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_TIRES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "6ffc2a99-d83a-49e5-809b-5d26e563192a",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "69eff2f6-d604-45bf-9255-f202c654456d",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "e1e9bf72-1056-431a-9a0f-095729656dd2",
                                  "value": "Shows Tread",
                                  "failItem": true
                              },
                              {
                                  "id": "1491add6-99ea-482d-a545-8f9fc538a1ca",
                                  "value": "Damage or Nail",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "dff12a2b-9d44-4f3c-8cbc-b850287def84",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Front Drive Axle Wheels / Rims / Valve Caps",
                  "required": false,
                  "description": "The wheels, rims, and valve caps on the rear driver’s side front axle, important for supporting the vehicle's load and ensuring proper tire pressure.",
                  "sequence": 14,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "9771c477-ccef-4a31-9b29-913f75308f64",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "38011291-5ac4-47c9-b35b-2bca5182f2c6",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "31768717-65b1-4902-b223-8a7112fea4ac",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "b55c1192-151d-438a-bd62-e310d3726e3b",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "579ad659-21ed-4040-9649-344d40fbdc90",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Rear Drive Axle Tires",
                  "required": false,
                  "description": "The tires on the rear driver’s side rear axle, responsible for supporting the vehicle’s weight and maintaining traction.",
                  "sequence": 15,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_TIRES",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "c1f7e473-9534-4e0d-9b37-118d2076f5c6",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "e8260297-0165-4216-b450-618a35651807",
                                  "value": "Bald",
                                  "failItem": true
                              },
                              {
                                  "id": "5b5fffc2-6d7d-4006-980a-a77118dff4cf",
                                  "value": "Shows Tread",
                                  "failItem": true
                              },
                              {
                                  "id": "bf73f1c9-4a3b-4a9c-967d-44a7acbe95f9",
                                  "value": "Damage or Nail",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "0726995c-ba89-4a8b-902b-ece78ba2aa4c",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              },
              {
                  "name": "Rear Driver Side Rear Drive Axle Wheels / Rims / Valve Caps",
                  "required": false,
                  "description": "The wheels, rims, and valve caps on the rear driver’s side rear axle, contributing to stability and weight distribution.",
                  "sequence": 16,
                  "vehicleComponentType": "REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS",
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "fe044df8-f1f8-4b4e-9cd3-47f1eac53e4f",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "53700c83-f84e-48e2-9a5f-9c48d4595e3a",
                                  "value": "Wear",
                                  "failItem": true
                              },
                              {
                                  "id": "7a408f42-472f-4674-89db-1b299bb48c12",
                                  "value": "Damage",
                                  "failItem": true
                              },
                              {
                                  "id": "e0a27f53-412d-4730-9bd1-687cc525ebb7",
                                  "value": "Valve Cap Missing",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "d5b1e2ea-a13b-41db-a72c-542ad2202581",
                  "sectionId": "717a56ed-5c26-4db0-8679-e2a77073ef58",
                  "iconName": null,
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "CHECKBOX"
              }
          ],
          "id": "717a56ed-5c26-4db0-8679-e2a77073ef58",
          "nameStatus": "FILLED",
          "expanded": false,
          "activeVehicleComponentType": "REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_TIRES"
      },
      {
          "name": "Tractor Mechanical Condition",
          "type": "GENERAL",
          "description": null,
          "photoRequired": false,
          "sequence": 5,
          "components": [
              {
                  "name": "Air Compressor",
                  "required": false,
                  "description": "Compresses air for the vehicle’s air brake system, ensuring proper operation of pneumatic components.",
                  "sequence": 0,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "CHECKBOXES",
                          "type": "OPTION_TYPE_CHECKBOXES",
                          "label": "Checkboxes",
                          "additionalLabel": "Add Checkbox",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "2849eb07-8df2-4ac2-a797-73b6d31b4aad",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "9f5b7e54-cdec-46fa-8371-3fdb29fcd3ad",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "0d1bed1e-e97c-404c-b6c0-5d0790e4089a",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "728f8550-36b9-48c7-9d19-fbbd342e4ebf",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "airPumpIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Air Lines",
                  "required": false,
                  "description": "Tubing that delivers compressed air from the compressor to various components, crucial for maintaining brake and other systems.",
                  "sequence": 1,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "fbd4fe67-2a6d-4ed0-a6c7-17fdb1f64d4a",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "cffaafca-2ca5-4f1b-a4c2-b3ce635f5379",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "a5f84809-0088-4415-9110-05e5a02d9d26",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "328866f9-ca8f-4d9c-924e-fdc284d537e6",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "pipeIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Brake Accessories",
                  "required": false,
                  "description": "Components associated with the vehicle's braking system, such as calipers and brake pads, ensuring effective stopping power.",
                  "sequence": 2,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "88a89b35-d2e8-490c-bcbf-bb9f3db66d92",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "6d79df2c-e55c-4bf6-a1a7-74c53d00436e",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "a7f6614c-44df-41da-bfd2-d1b85f907679",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "12b93e9d-c709-40f0-8d4d-d7ed4fda6bde",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "brakeCaliperIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Coupling Devices",
                  "required": false,
                  "description": "Equipment used to connect the tractor to the trailer, allowing for smooth and secure towing.",
                  "sequence": 3,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "ad34d44e-d1fb-472f-ab38-2a716c2f0ab4",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "ed33c381-5cc1-4da3-a69e-9122c14fd513",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "fbd529be-0de4-458d-933a-ce24e98f8838",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "9ea844c1-6846-478f-bec0-af52b1c0cbde",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "universalPointIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Drive Line",
                  "required": false,
                  "description": "Transmits power from the transmission to the wheels, ensuring that the vehicle moves efficiently.",
                  "sequence": 4,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "95337ae3-08ee-4cf4-9b81-ef007bfd61fa",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "38ba410a-7e21-47ae-b430-019a3dfbbc4a",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "072987f8-21b4-4ad9-ad80-b68e2d16962b",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "59711c48-6963-4513-a554-db7933086526",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "gearsIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Exhaust System",
                  "required": false,
                  "description": "Directs and reduces exhaust gases from the engine, ensuring proper emission control and vehicle performance.",
                  "sequence": 5,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "41206198-4cf9-48d3-8123-7d6698ae624e",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "4deb4738-dfb8-44d5-9169-ea853d7a27f8",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "564e584c-60cf-4377-b97d-e007cb7da5c0",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "6f21f47b-b4af-4249-8a5d-a4ba25c40922",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "exhaustSystemIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Muffler",
                  "required": false,
                  "description": "A component of the exhaust system that reduces noise produced by the engine during operation.",
                  "sequence": 6,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "0b3a8e40-6f3a-4c20-b5c3-d841e3244364",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "d6d3bf42-60c4-4ade-8e47-12421fc70c28",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "3de6f528-f3a2-4e4e-b7c1-2b26aeac4fe6",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "f7239374-09ee-4a6f-8bf6-760a87cbda10",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "mufflerIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Frame and Assembly",
                  "required": false,
                  "description": "The structural framework of the vehicle, supporting various components and ensuring the integrity of the tractor.",
                  "sequence": 7,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "24a44087-e212-4ed7-872b-c1687ba98dea",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "eb5c396e-9ef8-4846-82b8-4ca4b77ca4c5",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "b5d9fc24-ba00-43ef-8d9c-76d7aff456f5",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "c03f8e30-38c2-4130-8bba-10c0b0fc9809",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "automotiveIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Radiator",
                  "required": false,
                  "description": "Regulates engine temperature by circulating coolant, preventing overheating and ensuring optimal engine performance.",
                  "sequence": 8,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "a0145eb0-2049-4730-81aa-f91572ae8575",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "25876f5b-7e91-4a42-97f7-5cc458468afe",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "9a3e38c8-1d3e-449a-9d26-a1be3c738251",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "d945fc2b-e6d5-41ae-be5d-c534e01925bb",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "radiatorIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Rear End",
                  "required": false,
                  "description": "The rear axle and associated components that transfer power to the wheels, ensuring the tractor’s movement.",
                  "sequence": 9,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "1cba1d3c-0458-4041-a7e7-f9515dd147b3",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "af010aa6-02a8-4a77-956e-00033eaeea02",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "9ba97110-7834-4ac6-9d1f-3cbd101c8358",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "6832855d-3d94-4f18-89aa-9a60c0c6617b",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "differentialIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Front Axle",
                  "required": false,
                  "description": "A component that supports the front wheels and steering mechanism, ensuring smooth navigation and load distribution.",
                  "sequence": 10,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "309a96fb-1360-4ad3-9507-fa99c1653594",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "0206373d-12de-469b-a775-1ef231df2cef",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "2de600eb-b4f0-40cd-ad25-c39d51aa644d",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "e8ea27f8-8046-497a-89f7-0604df826139",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "rearWheelDriveIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Suspension System",
                  "required": false,
                  "description": "The system of springs and dampers that absorbs shock and maintains vehicle stability, providing a smoother ride.",
                  "sequence": 11,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "0368961d-6eeb-4ac3-9f31-1e65a03be1b3",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "a98fb23f-9ccd-4f67-9300-b76d80820a7a",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "d9cf470f-051c-4418-80d5-117b479c2c27",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "adc09b5a-4ac3-4ed7-8128-63a1e996905c",
                  "sectionId": "a1c67d00-bef8-4963-9b33-054516901a1e",
                  "iconName": "suspensionSystemIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              }
          ],
          "id": "a1c67d00-bef8-4963-9b33-054516901a1e",
          "nameStatus": "FILLED",
          "expanded": true,
          "activeVehicleComponentType": null
      },
      {
          "name": "Cabin Mechanical Condition",
          "type": "GENERAL",
          "description": null,
          "photoRequired": false,
          "sequence": 6,
          "components": [
              {
                  "name": "Parking Brakes",
                  "required": false,
                  "description": "Mechanical brakes that hold the vehicle stationary when parked, providing safety on inclines and during stops.",
                  "sequence": 0,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "91693867-cf6e-436d-8f73-54eed77f883b",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "fcb540d9-63de-4a15-9d4c-3cce84bc3c08",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "b400747f-f7dc-4e03-bfce-63867d9a0c69",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "33d9da9d-bf0f-4b79-9874-6122e6b8bf20",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "handbrakeIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Service Brakes",
                  "required": false,
                  "description": "The primary braking system of the vehicle, used for slowing or stopping while driving.",
                  "sequence": 1,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "de6126f1-04ff-4f4a-80f3-a15ca2e2251b",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "f1d55826-8f0d-4e47-b89a-9860dabde7e3",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "433ce739-1cb5-4577-8a71-4191fdb8113a",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "a8b4898d-e082-4cee-8664-bce126b95db3",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "brakeIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Defroster",
                  "required": false,
                  "description": "System that clears condensation or frost from the windshield, ensuring visibility during colder weather.",
                  "sequence": 2,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "3a3a3ece-6c14-40df-b163-4f86c9473b38",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "1aab8ca0-6fce-43d7-82c0-753221cfcaf9",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "cdfed7b6-d052-4eb4-bbb2-58c1a224ebeb",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "e57679a6-3b50-4f60-8e5b-730762e758d2",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "rearWindowDemisterIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Transmission",
                  "required": false,
                  "description": "Transfers power from the engine to the wheels, enabling the vehicle to move and change speeds efficiently.",
                  "sequence": 3,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "e9ed40e2-d6a7-4df2-9807-618fd0fedd63",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "39ee5008-57e0-4ed4-9066-704b559a92f1",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "035bd621-e82b-4b40-8cb3-e0e99753967f",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "74f2fbbc-36de-4684-9825-09faa556744a",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "transmissionIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Steering Mechanism",
                  "required": false,
                  "description": "System that allows for control and direction of the vehicle, crucial for smooth navigation and handling.",
                  "sequence": 4,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "3771a7b0-842c-480a-ba89-6f7f837c6a17",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "00dba12b-cdf8-4adb-86a5-e74de99807a2",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "bfc70481-fc44-472a-a0ad-42907097cc3a",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "599b0550-565a-4e4c-bd5a-b3ee8fac6ca7",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "steeringWheelIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Heater",
                  "required": false,
                  "description": "Provides warmth within the cabin, enhancing comfort for passengers, particularly in cold weather conditions.",
                  "sequence": 5,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "4a2e8be7-46bf-4857-ae13-bdbf6fa0844c",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "7c9e4e68-0d61-41a2-bda7-c253ba1ab6dc",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "36c9e8e8-3429-4372-9b70-f9521ee5c387",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "db02aba4-5ac8-48d4-a5a6-7c97a4b4eb59",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "windowHeaterIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "Highway Horn",
                  "required": false,
                  "description": "A louder horn used on highways to alert other drivers of the vehicle's presence, ensuring safety at higher speeds.",
                  "sequence": 6,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "a2b1e746-7af8-44b6-80d6-35aca9cd78aa",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "6f78c6ec-1a2d-495c-9ebd-d4146ee2c31f",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "8fdbd78e-cb6b-4e5b-b70e-7b4d07d3c39a",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "f6b4249c-e8c8-4a55-a195-b4f23661bf2b",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "hornIcon",
                  "nameStatus": "FILLED",
                  "expanded": false,
                  "type": "RADIO"
              },
              {
                  "name": "City Horn",
                  "required": false,
                  "description": "A quieter horn intended for use in urban areas to signal pedestrians or other vehicles, ensuring safety in congested environments.",
                  "sequence": 7,
                  "vehicleComponentType": null,
                  "options": [
                      {
                          "field": "RADIOS",
                          "type": "OPTION_TYPE_RADIO",
                          "label": "Radios",
                          "additionalLabel": "Add Radio",
                          "value": false,
                          "valueType": "valueList",
                          "valueList": [
                              {
                                  "id": "84c782d9-390d-48f3-be08-75497bba78fb",
                                  "value": "Checked and OK",
                                  "failItem": false
                              },
                              {
                                  "id": "74eeebba-2450-4bf1-b914-eeadfe430cb4",
                                  "value": "May Require Attention",
                                  "failItem": true
                              },
                              {
                                  "id": "fba465c9-3c99-47ab-9667-4218cf48cc10",
                                  "value": "Requires Immediate Attention",
                                  "failItem": true
                              }
                          ],
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_PASS",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Pass\"",
                          "additionalLabel": null,
                          "value": false,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      },
                      {
                          "field": "REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL",
                          "type": "OPTION_TYPE_CHECKBOX",
                          "label": "Require photo or comment for \"Fail\"",
                          "additionalLabel": null,
                          "value": true,
                          "valueType": "value",
                          "valueList": null,
                          "valueMap": null,
                          "additionalValueMap": null,
                          "renderItems": null
                      }
                  ],
                  "id": "ca0f54ac-0a3c-4acb-bd6b-25e3182b4953",
                  "sectionId": "e52022dd-a392-4f40-85a0-7f77382294e6",
                  "iconName": "hornIcon",
                  "nameStatus": "FILLED",
                  "expanded": true,
                  "type": "RADIO"
              },
          ],
          "id": "e52022dd-a392-4f40-85a0-7f77382294e6",
          "nameStatus": "FILLED",
          "expanded": true,
          "activeVehicleComponentType": null
      }
  ],
  "nameStatus": "FILLED"
};

export default mock;