import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, branch, renderNothing, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { getConfirmModal } from '../../../components/confirm';
import { PageActions } from '../../../components/page-actions';
import { ActionsElement } from '../../../components/actions-element';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, ZOrderWrapper } from '../../../ui';
// feature template/container-type
import ContainerTypeForm from './components/container-type-form';
import { report, tableSettings, columnSettings } from './settings';
import { selectItem, deleteListItemRequest, createOrUpdateListItemRequest } from './actions';
import { makeSelectItemList, makeSelectTotalCount, makeSelectListLoading } from './selectors';
//////////////////////////////////////////////////

const getModalWithComponent = (component: any, title: string) => ({
  p: '15px',
  component,
  options: {
    title,
    width: '320px',
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
  },
});

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

const setElementActions = (props: Object, listItem: Object) => {
  const { closeFixedPopup, handleUpdateListItem, handleDeleteListItem } = props;

  const iconColor = G.getTheme('colors.dark.blue');

  return [
    {
      frontIcon: I.renderEditIcon(iconColor),
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.getWindowLocale('actions:update', 'Update'),
      action: () => {
        handleUpdateListItem(listItem);
        closeFixedPopup();
      },
    },
    {
      frontIcon: I.trash(iconColor),
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.getWindowLocale('actions:delete', 'Delete'),
      action: () => {
        handleDeleteListItem(listItem);
        closeFixedPopup();
      },
    },
  ];
};

const getListActions = (props: Object) => {
  const { handleAddListItem } = props;

  const iconColor = G.getTheme('colors.white');

  return [
    {
      type: 'massAction',
      action: handleAddListItem,
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.getWindowLocale('titles:create', 'Create'),
      icon: I.createCloIcon(iconColor, '20px', '20px', 'transparent'),
    },
  ];
};

const renderElementActions = (data: Object, handleClickEditIcon: Function) => (
  <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
    {I.threeDots()}
  </Box>
);

const ContainerTypeTemplatesComponent = (props: Object) => (
  <Box p='15'>
    <ZOrderWrapper zIndex='2'>
      <TitlePanel
        withCount={true}
        noExportable={true}
        hideFilterInfo={true}
        popperWithCount={true}
        withoutQuickFilter={true}
        hiddenRightFilterInfo={true}
        title={G.getWindowLocale('titles:container-types', 'Container Types')} />
    </ZOrderWrapper>
    <ZOrderWrapper zIndex='1'>
      <Table
        report={report}
        hasSelectable={true}
        itemList={props.itemList}
        tableSettings={tableSettings}
        columnSettings={columnSettings}
        onOptionClick={props.selectItem}
        allChecked={G.isAllChecked(props.itemList)}
        renderRightStickedComponent={(data: Object) => renderElementActions(data, props.handleClickEditIcon)} />
    </ZOrderWrapper>
    <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
      <PageActions
        listActions={getListActions(props)}
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.length(R.filter((item: Object) => item.selected, R.or(props.itemList, [])))} />
    </AuthWrapper>
  </Box>
);

const enhance = compose(
  withFixedPopover,
  withHandlers({
    handleAddListItem: (props: Object) => () => {
      const { openModal, closeModal, createOrUpdateListItemRequest } = props;

      const component = (
        <ContainerTypeForm
          isEditMode={false}
          closeModal={closeModal}
          submitAction={createOrUpdateListItemRequest} />
      );
      const modal = getModalWithComponent(
        component,
        G.getWindowLocale('titles:container-type', 'Container Type'),
      );

      return openModal(modal);
    },
    handleUpdateListItem: (props: Object) => (item: Object) => {
      const { openModal, closeModal, createOrUpdateListItemRequest } = props;

      const component = (
        <ContainerTypeForm
          isEditMode={true}
          initialValues={item}
          closeModal={closeModal}
          submitAction={createOrUpdateListItemRequest} />
      );
      const modal = getModalWithComponent(
        component,
        G.getWindowLocale('titles:container-type', 'Container Type'),
      );

      return openModal(modal);
    },
    handleDeleteListItem: (props: Object) => (template: Object) => {
      const { openModal, closeModal, deleteListItemRequest } = props;

      const guid = R.prop(GC.FIELD_GUID, template);
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: G.getWindowLocale('titles:containers', 'Container(s)'),
        submitAction: () => {
          deleteListItemRequest(guid);
          closeModal();
        },
      });

      return openModal(modalContent);
    },
  }),
  withHandlers({
    handleClickEditIcon: (props: Object) => (e: Object, entity: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ActionsElement
            entity={entity}
            closeFixedPopup={props.closeFixedPopup}
            options={setElementActions(props, entity)} />
        ),
      })
    ),
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  branchGuid: makeSelectCurrentBranchGuid(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  deleteListItemRequest,
  createOrUpdateListItemRequest,
})(enhance(ContainerTypeTemplatesComponent));
