import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { serviceVendorTypeOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

const fieldStyles = {
  width: 220,
  afterTop: 15,
  afterRight: 15,
  errorTop: '110%',
  errorLeft: '5px',
  flexDirection: 'row',
  alignItems: 'center',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  inputsFlexDirection: 'column',
  justifyContent: 'space-between',
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: G.yupStringRequired,
  [GC.FIELD_MC_NUMBER]: G.yupStringNotRequired.max(8, G.getShouldBeFromToCharLocaleTxt(0, 8)),
  [GC.FIELD_CARRIER_DBA]: G.yupStringNotRequired.max(70, G.getShouldBeFromToCharLocaleTxt(0, 70)),
  [GC.FIELD_US_DOT_NUMBER]: G.yupStringNotRequired.max(12, G.getShouldBeFromToCharLocaleTxt(0, 12)),
  [GC.FIELD_LICENSE_NUMBER]: G.yupStringNotRequired.max(40, G.getShouldBeFromToCharLocaleTxt(0, 40)),
  [GC.FIELD_CARRIER_DUNS_NUMBER]: G.yupStringNotRequired.max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
  [GC.FIELD_ACCOUNTING_EXTERNAL_ID]: G.yupStringNotRequired.max(100, G.getShouldBeFromToCharLocaleTxt(0, 100)),
});

const editServiceVendorFieldSettings = [
  {
    ...fieldStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    loc: 'titles:company-name',
  },
  {
    ...fieldStyles,
    type: 'select',
    loc: 'titles:type',
    fieldName: GC.FIELD_TYPE,
    options: serviceVendorTypeOptions(),
  },
  {
    ...fieldStyles,
    type: 'toggle',
    loc: 'titles:active',
    fieldName: GC.FIELD_ACTIVE,
  },
  {
    ...fieldStyles,
    type: 'multiselect',
    loc: 'titles:service-type',
    options: 'serviceTypeOptions',
    fieldName: GC.FIELD_SERVICE_TYPES,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:dba',
    fieldName: GC.FIELD_CARRIER_DBA,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:accounting-external-id',
    fieldName: GC.FIELD_ACCOUNTING_EXTERNAL_ID,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:usdot-number',
    fieldName: GC.FIELD_US_DOT_NUMBER,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:mc-number',
    fieldName: GC.FIELD_MC_NUMBER,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:duns-number',
    fieldName: GC.FIELD_CARRIER_DUNS_NUMBER,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:url',
    fieldName: GC.FIELD_CARRIER_URL,
  },
  {
    ...fieldStyles,
    type: 'text',
    loc: 'titles:license-number',
    validate: G.curriedIsMinMax(0, 40),
    fieldName: GC.FIELD_LICENSE_NUMBER,
  },
];

export { validationSchema, editServiceVendorFieldSettings };
