import * as R from 'ramda';
import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Error } from '../../../../forms/ui';
import { Input } from '../../../../forms/formik/fieldset2/ui';
// icons
import * as I from '../../../../svgs';
// ui
import {
  Flex,
  Text,
  IconWrapper,
  ReactSelect,
} from '../../../../ui';
// feature template-inspection
import * as H from '../helpers';
import * as C from '../constants';
import FormField from './form-field';
import { CheckboxUI, MoveHandler } from '../ui';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const CheckboxComponent = ({
  name,
  value,
  label,
  setCheckboxValue,
  wrapperStyles = {},
} : props) => {
  const handleChange = useCallback((e: Event) => {
    const value = R.path(['currentTarget', 'checked'], e);

    setCheckboxValue(value);
  }, [setCheckboxValue]);

  const inputWrapperStyles = {
    mt: 0,
    flexDirection: 'row-reverse',
    ...wrapperStyles,
  }

  return (
    <FormField
      name={name}
      label={label}
      type='checkbox'
      inputWrapperStyles={inputWrapperStyles}
    >
      <CheckboxUI
        name={name}
        type='checkbox'
        checked={value}
        onChange={handleChange}
      />
    </FormField>
  );
};
const InputComponent = ({
  name,
  type,
  focus,
  label,
  width,
  maxLength,
  setInputValue,
} : props) => {
  const inputRef = useRef();
  const [value, setValue] = useState(name);

  const handleKeyPress = useCallback((e: Event) => {
    const { keyCode, charCode } = e;
    const code = R.or(keyCode, charCode);

    if (R.equals(code, GC.EVENT_KEY_CODE_ENTER)) {
      inputRef.current?.blur();
    }
  }, []);

  const handleChange = useCallback((e: Event) => {
    const value = R.path(['currentTarget', 'value'], e);

    setValue(value);
  }, []);

  const handleOnBlur = useCallback(() => {
    setInputValue(value);
  }, [value, setInputValue]);

  useLayoutEffect(() => {
    focus && inputRef.current?.focus();
  }, []);

  return (
    <FormField label={label} width={width}>
      <Input
        height={24}
        type={type}
        value={value}
        width={width}
        ref={inputRef}
        maxLength={maxLength}
        onBlur={handleOnBlur}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </FormField>
  );
};

const EditableName = ({
  name,
  label,
  status,
  errorMessage,
  handleNameEdit,
}: props) => {
  const height = G.ifElse(G.isNilOrEmpty(label), 16, 39);

  return (
    <Flex gap='8px' height={height}>
      { R.equals(status, C.INSPECTION_NAME_STATUS_FILLED) && <Text>{name}</Text> }
      { R.equals(status, C.INSPECTION_NAME_STATUS_EMPTY) && <Error title={errorMessage}>{errorMessage}</Error> }
      <IconWrapper
        mr={8}
        cursor='pointer'
        onClick={handleNameEdit}
        title={G.getWindowLocale('titles:inspection-edit-name', 'Edit name')}
      >
        {I.pencil(iconColor, 12, 12)}
      </IconWrapper>
    </Flex>
  );
};

const NameWithEdit = ({
  name,
  focus,
  label,
  width,
  status,
  maxLength,
  errorMessage,
  type = 'text',
  setInputValue,
  handleNameEdit,
}: Object) => {

  if (R.equals(status, C.INSPECTION_NAME_STATUS_EDIT)) {
    return (
      <InputComponent
        name={name}
        type={type}
        focus={focus}
        label={label}
        width={width}
        maxLength={maxLength}
        setInputValue={setInputValue}
      />
    );
  }

  return (
    <EditableName
      name={name}
      label={label}
      status={status}
      errorMessage={errorMessage}
      handleNameEdit={handleNameEdit}
    />
  );
};

const CheckboxOption = ({
  type,
  field,
  label,
  value,
  options,
  valueType,
  setOptionValue,
  additionalValueMap,
}) => {
  const handleToggleCheckbox = useCallback((checked) => {
    setOptionValue(field, checked, valueType);
  }, [field, setOptionValue, valueType]);

  const setFieldValue = useCallback(({ result, value }: Object) => {
    const newValues = {
      ...additionalValueMap,
      [value]: result,
    };

    setOptionValue(field, newValues, valueType, true);
  }, [field, setOptionValue, valueType]);


  return (
    <Flex gap={12} flexDirection='column' alignItems='flex-start'>
      <CheckboxComponent
        name={field}
        width='100%'
        value={value}
        label={label}
        setCheckboxValue={handleToggleCheckbox}
      />
      { R.and(value, G.isNotNilAndNotEmpty(options)) && (
        R.map((option: Object) => {
          const { label, valueField, inputType } = option;
          const optionValue = R.prop(valueField, additionalValueMap);

          return (
            <NameWithEdit
              fontSize={16}
              focus={false}
              label={label}
              key={valueField}
              type={inputType}
              name={optionValue}
              maxLength={C.MAX_NAME_LENGTH}
              status={C.INSPECTION_NAME_STATUS_EDIT}
              setInputValue={(result: number) => setFieldValue({ value: valueField, result })}
            />
          );
        }, options)
      )}
    </Flex>
  );
}

const InputsOption = ({
  id,
  field,
  label,
  values,
  options,
  inputType,
  valueType,
  setOptionValue,
}) => {
  const setFieldValue = useCallback(({ result, value }: Object) => {
    const newValues = {
      ...values,
      [value]: result,
    };

    setOptionValue(field, newValues, valueType);
  }, [field, setOptionValue, valueType]);

  return (
    <Flex gap={10} width='100%' flexDirection='column' alignItems='flex-start'>
      {
        G.isNotNilAndNotEmpty(label) && <Text pl={5} fontSize={12}>{label}</Text>
      }
      <Flex gap={8} width='100%'>
        {
          R.map((option: Object) => {
            const { label, valueField } = option;
            const optionValue = R.prop(valueField, values);

            return (
              <NameWithEdit
                width='100%'
                fontSize={16}
                focus={false}
                label={label}
                key={valueField}
                type={inputType}
                name={optionValue}
                maxLength={C.MAX_NAME_LENGTH}
                status={C.INSPECTION_NAME_STATUS_EDIT}
                setInputValue={(result: number) => setFieldValue({ value: valueField, result })}
              />
            );
          }, options)
        }
      </Flex>
    </Flex>
  );
}

const DropdownOption = ({
  id,
  field,
  label,
  values,
  options,
  minCount,
  inputType,
  valueType,
  setOptionValue,
  additionalLabel,
}) => {
  const disabled = R.lte(R.length(values), minCount);
  const notDraggable = R.equals(R.length(values), 1);

  const onDragEnd = useCallback(({ source, destination }: Object) => {
    const reordered = H.reorderList(values, R.prop('index', source), R.prop('index', destination));

    setOptionValue(field, reordered, valueType);
  }, [values, field, setOptionValue, valueType]);

  const setFieldValue = useCallback(({ result, option }: Object) => {
    const newValues = [...values];
    const newOption = R.find(R.propEq(R.prop('id', option), 'id'), newValues);

    newOption.value = result;

    setOptionValue(field, newValues, valueType);
  }, [values, field, setOptionValue, valueType]);

  const addDropdownOption = useCallback(() => {
    const newValues = [
      ...values,
      {
        value: null,
        failItem: false,
        id: G.generateGuid(),
      },
    ];

    setOptionValue(field, newValues, valueType);
  }, [values, field, setOptionValue, valueType]);

  const deleteDropdownOption = useCallback((option: Object) => {
    if (disabled) return;

    const newValues = R.filter(G.notPropEq(R.prop('id', option), 'id'), values);

    setOptionValue(field, newValues, valueType);
  }, [disabled, values, field, setOptionValue, valueType]);

  const handleToggleCheckbox = useCallback((option : Object, checked: boolean) => {
    const newValues = [...values];
    const newOption = R.find(R.propEq(R.prop('id', option), 'id'), newValues);

    newOption.failItem = checked;

    setOptionValue(field, newValues, valueType);

  }, [values, field, setOptionValue, valueType]);

  return (
    <Flex width='100%' flexDirection='column' alignItems='flex-start' gap={10}>
      <Text pl={5} fontSize={12}>{label}</Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided: Object) => (
            <Flex
              gap={12}
              width='100%'
              alignItems='normal'
              flexDirection='column'
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                G.mapIndexed((option: Object, index: number) => {
                  const { id, value, failItem } = option;

                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided: Object) => (
                        H.renderDraggable(provided.draggableProps, (
                          <div
                            width='100%'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Flex gap={8} width='100%' justifyContent='space-between'>
                              <MoveHandler
                                disabled={notDraggable}
                                {...provided.dragHandleProps}
                                onMouseDown={(e: Object) => e.currentTarget.focus()}
                              >
                                {I.moveIcon(iconColor, 16, 15)}
                              </MoveHandler>
                              <NameWithEdit
                                type='text'
                                name={value}
                                label={null}
                                fontSize={16}
                                focus={false}
                                width='calc(100% - 180px)'
                                maxLength={C.MAX_NAME_LENGTH}
                                status={C.INSPECTION_NAME_STATUS_EDIT}
                                setInputValue={(result: number) => setFieldValue({ option, result })}
                              />
                              <CheckboxComponent
                                name={field}
                                label={
                                  G.getWindowLocale('titles:inspection-fail-chosen', 'Fail item if chosen')
                                }
                                value={failItem}
                                setCheckboxValue={(checked: boolean) => handleToggleCheckbox(option, checked)}
                              />
                              <IconWrapper
                                cursor='pointer'
                                disabled={disabled}
                                onClick={() => deleteDropdownOption(option)}
                                title={G.getWindowLocale('titles:inspection-delete-item', 'Delete item')}
                              >
                                {I.remove(iconColor, 12, 12)}
                              </IconWrapper>
                            </Flex>
                          </div>
                        ))
                      )}
                    </Draggable>
                  );
                }, values)
              }
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
      <Flex
        gap={8}
        cursor='pointer'
        title={additionalLabel}
        onClick={addDropdownOption}
      >
        <IconWrapper pt={4}>
          {I.createCloIcon(iconColor, 16, 16, 'transparent')}
        </IconWrapper>
        <Text color={iconColor} fontSize={12}>{additionalLabel}</Text>
      </Flex>
    </Flex>
  );
}

const ToggleButton = ({ expanded, handleClick }: Object) => (
  <Flex
    p='0 8px'
    height='100%'
    cursor='pointer'
    justify='center'
    direction='column'
    pointerEvents='all'
    onClick={handleClick}
  >
    {
      G.ifElse(
        G.isTrue(expanded),
        I.arrowUpSimple(iconColor),
        I.arrowDownSimple(iconColor),
      )
    }
  </Flex>
);

const SelectDropdown = ({
  value,
  options,
  onChange,
}: Object) => {

  return (
    <ReactSelect
      width='100%'
      value={value}
      options={options}
      onChange={onChange}
      placeholder={G.getWindowLocale('titles:inspection-active-section', 'Active section')}
    />
  );
};

export {
  ToggleButton,
  InputsOption,
  NameWithEdit,
  CheckboxOption,
  SelectDropdown,
  DropdownOption,
  CheckboxComponent,
};
